<template>
    <b-row>
        <b-col cols="12">
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :ref="table_uid"
                    :data="policies_data"
                    :is_loading="is_loading"
                    :button_methods="button_methods"
                    :hide_action_buttons="true"
                    disable_delete
                    allow_edit
                    guideline_key="policies_table"
                >
                    <template #title>
                        Policies and Guidelines
                    </template>
                </Table>
        </b-col>
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { levelComputed ,peopleComputed, profileComputed, profileMethods } from '@/state/helpers'
import Swal from 'sweetalert2'
import { format, parseISO } from 'date-fns';
import _ from 'lodash';
export default {
    name: 'policiesAndGuidelines',
    components: {
        Table,
    },
    props:{
        initial_table_mode:{
            default: 'view',
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'policies',
        table_view_mode: 'view',
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        //* table computed methods
        columns(){
            //* get ref of current scope
            let policy_columns = [
                { id: 'title', header: 'Document Title', fillspace: 3, editor: 'text', 
                    template: (value) => {
                        if(value != ''){
                            return `<span class="action_text view_action cursor-pointer">${value.title}</span>`
                        }
                        else {
                            return '';
                        }
                    }
                },//{ content:"richSelectFilter" }},
                { id: 'created_at', header: 'Issue Date', fillspace: 1,
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
                { id: 'owner_name', header: 'Owner', fillspace: 1, editor: 'text' },
                { id: 'type', header: 'Type', fillspace: 1, editor: 'text', 
                    template: function(obj) {
                    if(obj.type) {
                        return obj.type.charAt(0).toUpperCase() + obj.type.slice(1)
                    } else {
                        return '' 
                    }
                } },
                { id: 'level_name', header: 'Org Structure', fillspace: 2, editor: 'text' },
                { id: 'status', header: 'Status', fillspace: 1, editor: 'text' },
                
            ];
            return policy_columns;
        },
        policies_data(){
            if(this.teams.policies == undefined){
                return [];
            }
            else{
                // let results = this.teams.policies.filter(obj => {
                //     if(obj.type == 'policy'|| obj.type == 'guidelines')
                //     return obj;
                // });
                return _.uniqBy(this.teams.policies, 'id');
                //return this.teams.policies;
            }
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'view_action':function(e, id){
                    let item = this.getItem(id.row);
                    console.log(item);
                       el.$router.push(`/handbook/${item.id}`);
                },
            }
            return onClick;
        },
    },
    methods:{
        ...profileMethods,
        //* table event handlers
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            if(row !== null){
                this.active_id = row.id;
            } else {
                this.active_id = -1
            }
        },
        saveTable(){
            this.$refs[this.table_uid].saveTable();
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
    },
    beforeMount(){
        this.table_view_mode = this.initial_table_mode;
    },
    mounted(){
    },
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
</style>