<template>
    <b-row>
        <b-col cols="12">
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :id="table_uid"
                    :ref="table_uid"
                    :data="values_data"
                    :is_loading="is_loading"
                    only_editable_columns
                    column_autoheight_key="how"
                    :active_id="null"
                    :validation="table_validation_rules"
                    @saveTable="saveTable"
                    @deleteRows="deleteRow"
                    :button_methods="button_methods" 
                    :edit_permissions="{manager:'values'}"
                    :required_permissions="['values']"
                    hide_action_buttons 
                    no_extra_rows
                    guideline_key="values_table"            
                >
                    <template #title>
                        Values
                    </template>
                    <template #buttons>  
                        <b-button size="sm" variant="primary" @click="addValuesModal({})" class="me-1" style="display: inline-block">
                            <font-awesome-icon :icon="['fas', 'fa-plus']" />
                        </b-button>
                    </template>
                </Table>
        </b-col>
        <valuesModal :payload="modal_payload" v-if="show_value_modal" @saveTable="saveTable" /> 
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { levelComputed ,peopleComputed, profileComputed, profileMethods } from '@/state/helpers'
import dateMixin from '@/mixins/dateMixin.js'
import _ from 'lodash'
import valuesModal from './profileModals/valuesModal.vue'
import Swal from 'sweetalert2'
export default {
    mixins:[ dateMixin ],
    name: 'values',
    components: {
        Table,
        valuesModal
    },
    props:{
        initial_table_mode:{
            default: 'view',
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'values',
        table_view_mode: 'view',
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        disableEdit: false,
        show_value_modal: false,
        modal_payload: {}
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'edit_action':function(e, id){
                    // let reminder = el.meeting_data.reminders.find(item => item.id == id);
                    //console.log(id)
                    console.log(id)
                    let item = this.getItem(id)
                    console.log(item)
                    el.addValuesModal(item)
                },
            }
            return onClick;
        },
        //* table computed methods
        columns(){
            // let el = this;
            //* get ref of current scope
            let values_columns = [
                { id: 'value', header: 'Value', editor: 'text', fillspace: 1,},
                { id: 'how', header: 'How', fillspace: 2, editor: 'textarea' },
            ];
            return values_columns;
        },
        isCompanyLevel(){
           return this.company_id == this.selected_level.id;
        },
        values_data(){
            if(this.teams.values == undefined){
                return [];
            }
            else{
                return this.teams.values;
            }
        },
        table_validation_rules(){
            return {
                "value": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === ''){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                // "level_id": function(item, obj){
                //     if(obj.is_empty){
                //         return true;
                //     }
                //     else{
                //         if(item === '' || item == undefined){
                //             return false;
                //         }
                //         else{
                //             return true;
                //         }
                //     }
                // },
                "how": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            };
        },
    },
    methods:{
        ...profileMethods,
        editRow(data) {
            let item = window.webix.$$('values').getItem(data[0].id)
            this.addValuesModal(item)
        },
        //* table event handlers
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            if(row !== null){
                this.active_id = row.id;
            } else {
                this.active_id = -1
            }
        },
        addValuesModal(item){
            this.modal_payload = _.cloneDeep(item);
            this.show_value_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('addValueModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "addValueModal") {
                        this.show_value_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        test() {
            window.webix.$$('values').adjustColumn()
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        saveTable(data){
            console.log(JSON.parse(JSON.stringify(data)))
            this.is_loading = true;
            let rows_to_save = [];
            let array = data
            if(!Array.isArray(data)) {
               array = [data]
            }
            array.forEach( row => {
                if(!row.is_empty){
                    let newRow = row.is_new;
                    var params = {
                        id: newRow ? -1 : row.id,
                        level_id: this.selected_level.id,
                        value: row.value,
                        how: row.how,
                    };
                    rows_to_save.push(params);
                }
            })

            let params = {
                level_values: rows_to_save,
            }
            //this.is_loading = true;
            this.saveValues(params).then(()=>{
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;     
                        this.$refs[this.table_uid].delete_array = []                   
                    })
                })
        },
        // insertEmptyRows(){
        //     let length = this.values_data.length;
        //     let remainder = length % 10;
        //     let empty_rows = 10 - remainder;

        //     // if(this.edit_mode){
        //     //     //* add an extra x count of rows foe editing
        //     //     empty_rows += 20;
        //     // }

        //     for(let i = 0; i < empty_rows; i++){
        //         console.log('a')
        //         // eslint-disable-next-line
        //         $$("values").add({id: `emptyRow${window.webix.uid()}`, is_empty: true,});
        //     }
        // },
        deleteRow(data) {
            let promises = [];
            this.is_loading = true;
            data.forEach( item => {
                promises.push(this.deleteValues({id: item.id}));
            })
            Promise.all(promises)
            .then(()=>{
                this.loadTeams(this.selected_level.id)
                .then(()=>{
                    this.$refs[this.table_uid].delete_array = []
                    this.is_loading = false;
                })
			})
            .catch(()=>{
                this.is_loading = false;
            })
        },
    },
    beforeMount(){
        this.table_view_mode = this.initial_table_mode;
    },
    mounted(){
        //this.is_loading = true
        window.webix.editors.textarea = window.webix.extend({
        render:function(){
            return window.webix.html.create("div", {
                "class":"webix_dt_editor webix_custom_text"
            }, "<textarea>");
        }
        }, window.webix.editors.text);
        // window.webix.$$(this.table_uid).refresh()
    },
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

</style>