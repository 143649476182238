<template>
    <b-row>
        <b-col cols="12">
            <Table 
                :columns="columns"
                class="friction"
                :uid="table_uid" 
                :ref="table_uid" 
                :data="friction_data"
                :default_values="default_values" 
                :is_loading="is_loading"
                :table_mode="table_view_mode"
                :active_id="active_id" 
                :button_methods="button_methods"
                :validation="table_validation_rules" 
                @saveTable="saveTable"
                @selectedRowEvent="selectedRowEvent" 
                @deleteRows="deleteRow" 
                @updateViewMode="handleTableModeUpdate"
                hide_action_buttons
                no_extra_rows
                :edit_permissions="{manager:'friction-risks'}"
                :required_permissions="['friction-risks']"
                guideline_key="friction_register_table"
                >
                <template #title>
                    Friction Register
                </template>
                <template #buttons>  
                    <b-button size="sm" variant="primary" @click="frictionModal({})" class="me-1" style="display: inline-block">
                        <font-awesome-icon :icon="['fas', 'fa-plus']" />
                    </b-button>
                </template>
            </Table>
            <FrictionModal :payload="modal_payload" v-if="show_friction_modal" @saveTable="saveTable" />
            <objectivesPlanModal
            v-if="show_objectives_plan_modal"
            :payload="modal_payload"
            @selectedNewID="handlePlanChangedEvent"
            />
            <HierarchyModal v-if="show_level_linking_modal" show_all_levels :payload="modal_payload" @selectedNewID="handleRowLevelsUpdate" @updatedNewID="handleRowLevelsUpdate"/>
            <ObjectiveModal v-if="show_objective_modal" :not_objective="true" :objective_type="'frictionAndRisks'" :payload="modal_payload" @selectedNewID="handleObjectiveUpdateEvent" @updateNewID="handleObjectiveUpdateEvent"/>
        </b-col>
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import _ from 'lodash'
import { levelComputed, peopleComputed, profileComputed, profileMethods, activityComputed, activityMethods, performanceComputed, performanceMethods } from '@/state/helpers'
import objectivesPlanModal from '../../objectives/components/misc/structureModals/objectivePlanModal.vue'
import HierarchyModal from '@/components/objectives/components/misc/hierarchyModal.vue'
import ObjectiveModal from '../../objectives/components/misc/objectiveModals/linkedObjectiveModal.vue'
import FrictionModal from './profileModals/frictionModal.vue'

export default {
    name: 'frictionRegister',
    components: {
        Table,
        HierarchyModal,
        objectivesPlanModal,
        ObjectiveModal,
        FrictionModal
    },
    props: {
        initial_table_mode: {
            default: 'view',
        }
    },
    data: () => ({
        //* vars relating to the table component
        table_uid: 'frictionRegister',
        table_view_mode: 'view',
        new_view_mode: '',
        show_level_linking_modal: false,
        show_objectives_plan_modal: false,
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array: [],
        show_objective_modal: false,
        show_friction_modal: false,
        modal_payload: {}
    }),
    computed: {
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        ...activityComputed,
        ...performanceComputed,
        friction_data() {
            if (!this.teams.frictions) {
                return [];
            }
            else {
                let return_data = this.teams.frictions.map(item => {
                    item['friction_type'] = item.type;
                    return item;
                })
                return_data = _.uniqBy(return_data, 'id');
                return return_data;
            }
            //return this.teams.frictions != undefined ?
            //    this.teams.frictions : [] ;
        },
        default_values() {
            let el = this;
            return [
                { id: "level_id", value: el.selected_level.id },
            ]
        },
        //* table computed methods
        columns() {
            //* get ref of current scope
            var el = this;
            let friction_columns = [
                { id: 'name', header: 'Friction', fillspace: 2, editor: 'text' },
                {
                    id: "milestone_objective_id",
                    header: "Linked Objective",
                    width: 250,
                    template:function(obj){
                        if(obj.is_empty){
                            return '';
                        }
                            let name = 'Not Linked'; 
                            let found_objective = el.teams.linkable_objectives.find( (item) => {
                                return item.id == obj.milestone_objective_id
                            })
                            
                            if(found_objective != undefined){
                                // return `<div class="show_objective_modal show_hierarchy_text">
                                //     <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class=" svg-inline--fa fa-pen"><path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" class=""></path></svg>
                                //         <span class="ms-1">Awe</span>
                                // </div>`
                                //return `<div class="show_objective_modal show_hierarchy_text">Awe</div>`
                                console.log(JSON.parse(JSON.stringify(found_objective)))
                                name = found_objective.name;
                            }
                            return `<span>
                                    ${name} 
                                </span>`
                    }
                },
                {
                    id: "level_id",
                    //editor: "combo",
                    header: 'Linked Level',
                    sort: "text",
                    width: 250,
                    template:function(obj){
                        if(obj.level_id != undefined){
                            let index = _.findIndex(el.levels, {id: obj.level_id });
                            let level_name = '';
                            if(index != -1){
                                level_name = el.levels[index].name;
                            }

                            if(level_name != '') {
                                return `<span>${level_name}</span>`
                            }
                        }
                        else{
                            return ''
                        }
                    },
                },
                { id: 'friction_type', header: 'Type', fillspace: 1, editor: 'text' },
            ];
            return friction_columns;
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'show_objectives_plan_modal':function(e, id){
                    console.log('no issues');
                    let item = this.getItem(id);
                    console.log(item);
                    el.showObjectivePlanModal(item);
                },
                'show_hierarchy_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showHierarchyModal(item);
                },
                'show_objective_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showObjectiveModal(item);
                },
                'edit_action':function(e, id){
                    // let reminder = el.meeting_data.reminders.find(item => item.id == id);
                    //console.log(id)
                    console.log(id)
                    let item = this.getItem(id)
                    console.log(item)
                    el.frictionModal(item)
                },
            }
            return onClick;
        },
        getAllObjectives(){
            if(this.teams.linkable_objectives.length > 0) {
                return this.teams.linkable_objectives
            } else {
                return []
            }
        },
        table_validation_rules() {
            //eslint-disable-next-line
            let el = this;
            return {
                "name": function (item, obj) {
                    if (obj.is_empty) {
                        return true;
                    }
                    else {
                        if (item === '' || item == undefined) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                },
                "level_id": function (item, obj) {
                    if (obj.is_empty) {
                        return true;
                    }
                    else {
                        if (item === '' || item == undefined) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                },
                "friction_type": function (item, obj) {
                    if (obj.is_empty) {
                        return true;
                    }
                    else {
                        if (item === '' || item == undefined) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                },
            };
        },
    },
    methods: {
        ...profileMethods,
        ...activityMethods,
        ...performanceMethods,
        frictionModal(item){
            this.modal_payload = _.cloneDeep(item);
            this.show_friction_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('frictionModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "frictionModal") {
                        this.show_friction_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        //* table event handlers
        selectedRowEvent(row) {
            //* set selected row id  in your vuex store
            this.active_id = row ? row.id : -1;
        },
        showObjectiveModal(payload){
            /*if(this.changes_made_flag){
                Swal.fire('Please save new rows before making hierarchy changes')
            }*/
            //else{
                if(this.new_view_mode === 'edit'){
                    this.modal_payload = _.cloneDeep(payload);
                    this.modal_payload['modal_type'] = 'frictionAndRisks'
                    let options = this.teams.linkable_objectives;
                    let index = _.findIndex(options, {id: Number(payload.milestone_objective_id)});
                    if(index != -1){
                        this.modal_payload['name'] = options[index].name
                    } else {
                        this.modal_payload['name'] = 'No objective selected'
                    }
                    this.modal_payload['parent_objective_id'] = Number(payload.milestone_objective_id)
                    this.show_objective_modal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show('linkedObjectiveModal');
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "linkedObjectiveModal") {
                                this.show_objective_modal = false;
                                this.modal_payload = null;
                            }
                        });
                    })
                }
            //}
        },
        handleObjectiveUpdateEvent(newID){
            console.log(newID)
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            item['milestone_objective_id'] = newID;

            console.log(item);
            console.log(this.modal_payload.id)
            table.updateItem(this.modal_payload.id, item);
            this.$bvModal.hide('linkedObjectiveModal');
        }, 
        handleRowLevelsUpdate(payload){
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.item_id);
            item['level_id'] = Number(payload);
            table.updateItem(this.modal_payload.item_id, item)
            this.$bvModal.hide('hierarchyModal');
        },
        showHierarchyModal(payload){
            this.modal_payload = _.cloneDeep(payload);
            console.log(this.modal_payload)
            let level_id = 0
            if(payload.level_id == undefined) {
                level_id = this.selected_level.id
            } else {
                level_id = payload.level_id
            }
            let level = this.levels.find((lvl) => lvl.id == level_id);
            console.log(level)
            this.modal_payload = { id: payload.level_id, parent_id: level.parent_id, item_id: payload.id, level_id: payload.level_id};
            this.modal_payload['modal_type'] = 'Objective'
            this.show_level_linking_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('hierarchyModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "hierarchyModal") {
                        this.show_level_linking_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        handlePlanChangedEvent(newID){
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            item['objective_plan_id'] = Number(newID);
            table.updateItem(this.modal_payload.id, item);
            this.$bvModal.hide('objectivePlanModal')
        },
       
        // showObjectivePlanModal(payload){
        //     if(this.new_view_mode == 'edit'){
        //         this.modal_payload = _.cloneDeep(payload);
        //         let options = this.getAllObjectives;
        //         let index = _.findIndex(options, {id: Number(payload.milestone_objective_id)});
        //         if(index != -1){
        //             let objective_plan_name = options[index].value;
        //             this.modal_payload['objective_plan_name'] = objective_plan_name
        //         } else {
        //             this.modal_payload['objective_plan_name'] = ''
        //         }
        //         this.modal_payload['modal_type'] = 'Objective'
        //         this.modal_payload['parent_id'] = payload.parent_objective_id;
        //         this.show_objectives_plan_modal = true;
        //         this.$nextTick(()=>{
        //             this.$bvModal.show('objectivePlanModal');
        //             this.$root.$once("bv::modal::hidden", (event) => {
        //                 if (event.type == "hidden" && event.componentId == "objectivePlanModal") {
        //                     this.show_objectives_plan_modal = false;
        //                     this.modal_payload = null;
        //                 }
        //             });
        //         })
        //     }
        // },
        handleTableModeUpdate(new_mode){ 
            this.new_view_mode = new_mode
        },
        saveTable(data) {
            console.log(JSON.parse(JSON.stringify(data)))
            let level_frictions = [];
            data.forEach((row) => {
                let save_row = {
                    id: row.is_new ? 0 : row.id,
                    objective_id: row.objective_id ? row.objective_id : '',
                    level_id: row.level_id,
                    name: row.name,
                    type: row.friction_type,
                }
                level_frictions.push(save_row);
            })
            this.is_loading = true;
            this.saveFrictions({ level_frictions: level_frictions })
                .then(() => {
                    this.loadTeams(this.selected_level.id)
                        .then(() => {
                            this.new_view_mode = 'view'
                            this.$refs[this.table_uid].table_mode = 'view';
                            this.is_loading = false;
                        })
                })
                .catch(() => { this.is_loading = false });
        },
        deleteRow(data) {
            let promises = [];
            data.forEach(item => {
                promises.push(this.deleteFrictions({ id: item.id }));
            })
            this.is_loading = true;
            Promise.all(promises)
                .then(() => {
                    this.loadTeams(this.selected_level.id)
                        .then(() => {
                            this.$refs[this.table_uid].table_mode = 'view';
                            this.is_loading = false;
                        })
                })
                .catch(() => {
                    this.is_loading = false;
                })
        },
    },
    beforeMount() {
        this.table_view_mode = this.initial_table_mode;
    },
    mounted() { },
}
</script>
<style>
    .friction .maxHeight {
        max-height: 50vh;
    }

    .friction .maxHeight .webix_ss_body{
        max-height: 50vh;
        overflow-y: auto;
    }
</style>
<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
</style>