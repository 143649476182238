<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-14T11:24:47+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T08:05:23+02:00
@Copyright: Diversitude 2021
-->

<template>

    <!-- <b-button @click="setComponentViewState(null)" size="lg" style="font-size: 55px; background-color: white">></b-button> -->
    <div class="w-100 shadow-lg bg-white rounded" style="min-height: 99vh !important; background-color: #F4F4F4 !important; overflow: auto; overflow-x: hidden !important">     
        <b-card class="d-flex mx-auto page_container_styles" style="justify-content: center; background-color: #F4F4F4 !important; overflow: auto !important; min-height: 99vh !important; overflow-x: hidden !important">
            <!-- <div class="d-flex w-100 m-1" style="justify-content: center;">
                   
            </div> -->
                <!-- <b-card id="card-1" class="mb-1 shadow-none structure-card" v-if="!jobDetailsEditMode" >
                    <b-card-header v-b-toggle.accordion-1 header-bg-variant="light" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="jobDetails" href="javascript: void(0);">
                                    <i class="fas me-1 structure-card" style="font-size: 1.4em !important; color: #CB6CC3" :class="collapse_states['jobDetails'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    <span class="ms-1" style="font-size: 22px; color: #6E84A7">{{selectedJobName}}</span>
                                </a>
                            </h6>
                    </b-card-header>
                    <b-collapse id="accordion-1" role="tabpanel" visible @show="handleCollapseAction('jobDetails')" @hidden="handleCollapseAction('jobDetails')">
                        <b-card-body>
                            <b-card-text>
                                <b-row>
                                    <div v-if="this.loaded_tables.jobDetails == true" class="mt-1">
                                        <JobDetailsHeader />
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card> -->
                <div class="w-100 sizing_change" style="display: inline-flex; justify-content: center;">
                    <span class="ms-3 mt-3 h-100"><font-awesome-icon :icon="['fas', 'angle-left']" @click="$emit('exit')" style="height: 50px !important; width: 50px !important; margin-top: 5px; color: #CB6CC3" /></span>
                    <b-col class="btn-group m-2 w-100 d-flex" style="justify-content: space-between; vertical-align: middle;">
                        <span class="d-flex" style="flex-direction: column;">
                            <span class="purple_dark" style="font-size: 40px;">
                                Job Details
                            </span>
                            <span>
                                You are viewing job Details for a specific Job Profile
                            </span>
                        </span>
                        <span class="mt-5 pt-3">
                        <span class="cursor-pointer" v-if="!jobDetailsEditMode && levelJob"  @click="enableJobEditMode(!jobDetailsEditMode)" style="color: #50A5F1; font-size: 12px;"> Edit <font-awesome-icon :icon="['fas', 'edit']" style="color: #50A5F1" /></span>
                        <span class="cursor-pointer" v-if="jobDetailsEditMode && levelJob" @click="enableJobEditMode(!jobDetailsEditMode)" style="color: #50A5F1; font-size: 12px;"> View <font-awesome-icon :icon="['fas', 'edit']" style="color: #50A5F1" /></span>
                        </span>
                    </b-col>
                </div>
                <div clas="m-3" style="border-radius: 20px;">
                    <JobDetailsHeader />
                </div>
                <b-card id="card-2" class="mb-1 details-cards shadow-none">
                    <b-card-header v-b-toggle.accordion-2 header-tag="header" class="details-cards" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="assignedPeople" href="javascript: void(0);">
                                    <i class="fas me-1" style="font-size: 1.4em !important; color: #CB6CC3" :class="collapse_states['assignedPeople'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    <span class="ms-1" style="font-size: 16px; color: #736BA5">Assigned People</span>
                                </a>
                            </h6>
                        </b-card-header>
                    <b-collapse id="accordion-2" role="tabpanel" visible @show="handleCollapseAction('assignedPeople')" @hidden="handleCollapseAction('assignedPeople')">
                        <b-card-body >
                            <b-card-text>
                                <b-row>
                                    <div v-if="this.loaded_tables.assignedPeople == true" class="mt-1">
                                        <AssignedPeopleTable />
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <!-- <b-card id="card-3" class="mb-1 shadow-none">
                    <b-card-header v-b-toggle.accordion-3 :header-bg-variant="collapse_states['candidates'] == true ? 'Secondary' : 'light'" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="candidates" href="javascript: void(0);">
                                    <i class="fas me-1" style="font-size: 1.4em !important; color: #CB6CC3" :class="collapse_states['candidates'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    <span class="ms-1" style="font-size: 16px; color: #736BA5">Candidates</span>
                                </a>
                            </h6>
                        </b-card-header>
                    <b-collapse id="accordion-3" role="tabpanel" @show="handleCollapseAction('candidates')" @hidden="handleCollapseAction('candidates')">
                        <b-card-body >
                            <b-card-text>
                                <b-row>
                                    <div v-if="this.loaded_tables.candidates == true" class="mt-1">
                                        <candidateTable />
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card> -->
                <b-card id="card-4" class="mb-1 shadow-none details-cards" >
                    <b-card-header v-b-toggle.accordion-4 class="details-cards" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="skills" href="javascript: void(0);">
                                    <i class="fas me-1 structure-card" style="font-size: 1.4em !important; color: #CB6CC3" :class="collapse_states['skills'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    <span class="ms-1" style="font-size: 16px; color: #736BA5">Skills</span>
                                </a>
                            </h6>
                    </b-card-header>
                    <b-collapse id="accordion-4" role="tabpanel" @show="handleCollapseAction('skills')" @hidden="handleCollapseAction('skills')">
                        <b-card-body>
                            <b-card-text>
                                <b-row>
                                    <div v-if="this.loaded_tables.skills == true" class="mt-1">
                                        <skills />
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card id="card-5" class="mb-1 shadow-none details-cards">
                    <b-card-header v-b-toggle.accordion-5 class="details-cards" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="responsibilities" href="javascript: void(0);">
                                    <i class="fas me-1" style="font-size: 1.4em !important; color: #CB6CC3" :class="collapse_states['responsibilities'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    <span class="ms-1" style="font-size: 16px; color: #736BA5">Responsibilities</span>
                                </a>
                            </h6>
                        </b-card-header>
                    <b-collapse id="accordion-5" role="tabpanel" @show="handleCollapseAction('responsibilities')" @hidden="handleCollapseAction('responsibilities')">
                        <b-card-body >
                            <b-card-text>
                                <b-row>
                                    <div v-if="this.loaded_tables.responsibilities == true" class="mt-1">
                                        <ResponsibilityTable />
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card id="card-6" class="mb-1 shadow-none details-cards">
                    <b-card-header v-b-toggle.accordion-6 class="details-cards" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="roles" href="javascript: void(0);">
                                    <i class="fas me-1" style="font-size: 1.4em !important; color: #CB6CC3" :class="collapse_states['roles'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    <span class="ms-1" style="font-size: 16px; color: #736BA5">Roles</span>
                                </a>
                            </h6>
                        </b-card-header>
                    <b-collapse id="accordion-6" role="tabpanel" @show="handleCollapseAction('roles')" @hidden="handleCollapseAction('roles')">
                        <b-card-body >
                            <b-card-text>
                                <b-row>
                                    <div v-if="this.loaded_tables.roles == true" class="mt-1">
                                        <RolesTable />
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <!-- <b-card id="card-7" class="mb-1 shadow-none">
                    <b-card-header v-b-toggle.accordion-7 :header-bg-variant="collapse_states['onboarding'] == true ? 'Secondary' : 'light'" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="onboarding" href="javascript: void(0);">
                                    <i class="fas me-1" style="font-size: 1.4em !important; color: #CB6CC3" :class="collapse_states['onboarding'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    <span class="ms-1" style="font-size: 16px; color: #736BA5">Onboarding</span>
                                </a>
                            </h6>
                        </b-card-header>
                    <b-collapse id="accordion-7" role="tabpanel" @show="handleCollapseAction('offboarding')" @hidden="handleCollapseAction('onboarding')">
                        <b-card-body >
                            <b-card-text>
                                <b-row>
                                    <div v-if="this.loaded_tables.onboarding == true" class="mt-1">
                                        Onboarding
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card> -->
                <!-- <b-card id="card-8" class="mb-1 shadow-none">
                    <b-card-header v-b-toggle.accordion-8 :header-bg-variant="collapse_states['offboarding'] == true ? 'Secondary' : 'light'" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="offboarding" href="javascript: void(0);">
                                    <i class="fas me-1" style="font-size: 1.4em !important; color: #CB6CC3" :class="collapse_states['offboarding'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    <span class="ms-1" style="font-size: 16px; color: #736BA5">Offboarding</span>
                                </a>
                            </h6>
                        </b-card-header>
                    <b-collapse id="accordion-8" role="tabpanel" @show="handleCollapseAction('offboarding')" @hidden="handleCollapseAction('offboarding')">
                        <b-card-body >
                            <b-card-text>
                                <b-row>
                                    <div v-if="this.loaded_tables.offboarding == true" class="mt-1">
                                      offboarding
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card> -->

        </b-card>
</div>
</template>

<script>
import {
    levelComputed,
    peopleComputed,
    profileComputed,
    profileMethods,
    peopleMethods
    } from '@/state/helpers'
//import Carousel from './pageComponents/carousel.vue'
import skills from '../skillsTable.vue'
import _ from 'lodash'
import JobDetailsHeader from './jobDetailsHeader.vue';
import AssignedPeopleTable from '../assignedPeopleTable.vue';
//import candidateTable from '../candidateTable.vue'
import RolesTable from '../rolesTable.vue';
import ResponsibilityTable from '../responsibilityTable.vue';
export default {
    name: "jobDetailsPage",
    components:{
       skills,
       JobDetailsHeader,
       AssignedPeopleTable,
       //candidateTable,
       ResponsibilityTable,
       RolesTable,
    },
    props: {

    },
    beforeDestroy() {
        //this.setSelectedJobId(null)
        this.getLevelJob(null)
    },
    mounted(){
        //this.setLoading(false);
    },
    data: () => ({
        selected_item: 1,
        options: [],
        collapse_states:{
            jobDetails: true,
            assignedPeople: true,
            candidates: false,
            skills: false,
            responsibilities: false,
            roles: false,
            onboarding: false,
            offboarding: false
        },
        loaded_tables:{
            jobDetails: true,
            assignedPeople: true,
            candidates: false,
            skills: false,
            responsibilities: false,
            roles: false,
            onboarding: false,
            offboarding: false
        },
    }),
    methods:{
        ...profileMethods,
        ...peopleMethods,
        // setFilter(value) {
        //     this.selected_filter = value
        // },
        // setYearFilter(value) {
        //     this.selected_year_filter = value
        // }
        handleCollapseAction(collapse_item){
            this.collapse_states[collapse_item] = !this.collapse_states[collapse_item];
            //if new state is true then refresh tables for the collapse
            if(this.collapse_states[collapse_item] == true){
                switch(collapse_item){
                case 'jobDetails':{
                    if(this.loaded_tables[collapse_item] == true) {
                        //window.webix.$$('jobSkillsTable').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true;
                    }
                    setTimeout(() => {
                        document.getElementById("card-1").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200)
                    break;
                }
                case 'assignedPeople':{
                    if(this.loaded_tables[collapse_item] == true) {
                        window.webix.$$('assignedPeople').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true;
                    }
                    setTimeout(() => {
                        document.getElementById("card-2").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200)
                    break;
                }
                case 'candidates':{
                    if(this.loaded_tables[collapse_item] == true) {
                        //window.webix.$$('jobSkillsTable').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true;
                    }
                    setTimeout(() => {
                        document.getElementById("card-3").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200)
                    break;
                }
                case 'skills':{
                    if(this.loaded_tables[collapse_item] == true) {
                        window.webix.$$('jobSkillsTable').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true;
                    }
                    setTimeout(() => {
                        document.getElementById("card-4").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200)
                    break;
                }
                case 'responsibilities':{
                    if(this.loaded_tables[collapse_item] == true) {
                        window.webix.$$('responsibilityTable').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true
                    }
                    setTimeout(() => {
                        document.getElementById("card-5").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200)
                    break;
                }
                case 'roles':{
                    if(this.loaded_tables[collapse_item] == true) {
                        window.webix.$$('rolesTable').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true
                    }
                    setTimeout(() => {
                        document.getElementById("card-6").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200)
                    break;
                }
                case 'offboarding':{
                    if(this.loaded_tables[collapse_item] == true) {
                        //window.webix.$$('test').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true
                    }
                    setTimeout(() => {
                        document.getElementById("card-7").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200)
                    break;
                }
                case 'onboarding':{
                    if(this.loaded_tables[collapse_item] == true) {
                        //window.webix.$$('test').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true
                    }
                    setTimeout(() => {
                        document.getElementById("card-8").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200)
                    break;
                }
                }
            }
        }
    },

    computed:{
        ...levelComputed,
        ...profileComputed,
        ...peopleComputed,
        selectedJobName(){
            //* get job name by using the activeJobId from vuex store ( peopleComputed -> activeJobId ) ;
            let job_title = 'Edit to add Job';
            let index = _.findIndex(this.companyJobs, { id: this.levelJob.id});
            if(index != -1){
                job_title = this.companyJobs[index].title;
            }
            return job_title;
        },
    },
}

</script>

<style>
.page_select_styling {
    width: 666px !important;
    height: 50px !important
}
.page_container_styles {
    /* width: 1530px !important; */
    width: 100%;
    /* height: 100% !important; */
    padding: 0 auto
}
.arrow_styles {
    width: 30px;
    height: 30px;
    border: 10px solid #CB6CC3;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}
.custom_border {
    border: 1px solid #BFC8D6;
    box-shadow: 0 0 2px 2px #8881B3;
}
.details-cards {
    background-color: #F4F4F4;
}
@media (max-width: 1561px) {
    .sizing_change {
        flex-direction: column;
    }
}
</style>
