<template>
        <!-- <div v-show="!toggleViewValue">
                <b-card id="card-1" class="mb-1 shadow-none collapse-card structure-card" >
                    <b-button size="sm" variant="primary" style="float: right" @click="toggleViewValue = true">
                            Tabs View
                    </b-button>
                    <b-button class="me-2" v-show="all_expanded" variant="primary" size="sm" style="float: right" @click="collapseAll()">
                            Collapse All Tables
                    </b-button>
                    <b-button class="me-2" v-show="!all_expanded" variant="primary" size="sm" style="float: right" @click="expandAll()">
                            Expand All Tables
                    </b-button>
                    <b-card-header v-b-toggle.accordion-1 header-bg-variant="light" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="value" href="javascript: void(0);">
                                    <i class="fas me-1 structure-card" :class="collapse_states['value'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    Values
                                </a>
                            </h6>
                    </b-card-header>
                    <b-collapse id="accordion-1" role="tabpanel" @show="handleCollapseAction('value')" @hidden="handleCollapseAction('value')">
                        <b-card-body>
                            <b-card-text>
                                <b-row>
                                    <div v-if="this.loaded_tables.value == true" class="mt-1">
                                        <values />
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card id="card-2" class="mb-1 shadow-none collapse-card">
                    <b-card-header v-b-toggle.accordion-2 :header-bg-variant="collapse_states['work_groups'] == true ? 'Secondary' : 'light'" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="work_groups" href="javascript: void(0);">
                                    <i class="fas me-1" :class="collapse_states['work_groups'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    Work Groups & Members
                                </a>
                            </h6>
                        </b-card-header>
                    <b-collapse id="accordion-2" role="tabpanel" @show="handleCollapseAction('work_groups')" @hidden="handleCollapseAction('work_groups')">
                        <b-card-body >
                            <b-card-text>
                                <b-row>
                                    <div v-if="this.loaded_tables.work_groups == true" class="mt-1">
                                        <workGroups  :noEdit="false"/>
                                        <workGroupMembers v-if="this.selected_team_id != -1" :noEdit="false" />
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card id="card-3" class="mb-1 shadow-none collapse-card">
                    <b-card-header v-b-toggle.accordion-3 header-bg-variant="light" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="products_services" href="javascript: void(0);">
                                    <i class="fas me-1" :class="collapse_states['products_services'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    Products & Services
                                </a>
                            </h6>
                    </b-card-header>
                    <b-collapse id="accordion-3" role="tabpanel" @show="handleCollapseAction('products_services')" @hidden="handleCollapseAction('products_services')">
                        <b-card-body>
                            <b-card-text>
                                <b-row>
                                    <div v-if="this.loaded_tables.products_services == true" class="mt-1">
                                        <processMap />
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                
                <b-card id="card-4" class="mb-1 shadow-none collapse-card">
                    <b-card-header v-b-toggle.accordion-4 header-bg-variant="light" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="policy" href="javascript: void(0);">
                                    <i class="fas me-1" :class="collapse_states['policy'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    Policy & Guidelines
                                </a>
                            </h6>
                    </b-card-header>
                    <b-collapse id="accordion-4" role="tabpanel" @show="handleCollapseAction('policy')" @hidden="handleCollapseAction('policy')">
                        <b-card-body v-if="loaded_tables.policy == true">
                            <b-card-text>
                                <b-row>
                                    <div class="mt-1">
                                        <policiesAndGuidelinesTable />
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card id="card-5" class="mb-1 shadow-none collapse-card">
                    <b-card-header v-b-toggle.accordion-5 header-bg-variant="light" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="processes_procedures" href="javascript: void(0);">
                                    <i class="fas me-1" :class="collapse_states['processes_procedures'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    Processes & Procedures
                                </a>
                            </h6>
                    </b-card-header>
                    <b-collapse id="accordion-5" role="tabpanel" @show="handleCollapseAction('processes_procedures')" @hidden="handleCollapseAction('processes_procedures')">
                        <b-card-body v-if="loaded_tables.processes_procedures == true">
                            <b-card-text>
                                <b-row>
                                    <div class="mt-1">
                                        <processesAndProceduresTableVue />
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card id="card-6" class="mb-1 shadow-none collapse-card">
                    <b-card-header v-b-toggle.accordion-6 :header-bg-variant="collapse_states['tools_services'] == true ? 'Secondary' : 'light'" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="tools_services" href="javascript: void(0);">
                                    <i class="fas me-1" :class="collapse_states['tools_services'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    Providers & Tools
                                </a>
                            </h6>
                    </b-card-header>
                    <b-collapse id="accordion-6" role="tabpanel" @show="handleCollapseAction('tools_services')" @hidden="handleCollapseAction('tools_services')">
                        <b-card-body v-if="loaded_tables.tools_services == true">
                            <b-card-text>
                                <b-row>
                                    <div class="mt-1">
                                        <serviceProviders v-if="loading_index.tools_services >= 1" />
                                        <communicationTools v-if="loading_index.tools_services >= 2"/>
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                <b-card id="card-7" class="mb-1 shadow-none collapse-card">
                    <b-card-header v-b-toggle.accordion-7 header-bg-variant="light" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="friction_risk" href="javascript: void(0);">
                                    <i class="fas me-1" :class="collapse_states['friction_risk'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    Friction & Risks
                                </a>
                            </h6>
                    </b-card-header>
                    <b-collapse id="accordion-7" role="tabpanel" @show="handleCollapseAction('friction_risk')" @hidden="handleCollapseAction('friction_risk')">
                        <b-card-body v-if="loaded_tables.friction_risk == true">
                            <b-card-text>
                                <b-row>
                                    <div class="mt-1">
                                        <frictionRegister v-if="loading_index.friction_risk >= 1"/>
                                        <riskRegister v-if="loading_index.friction_risk >= 2"/>                                        
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>      
                </b-card>
                <b-card id="card-8" class="mb-1 shadow-none collapse-card">
                    <b-card-header v-b-toggle.accordion-8 header-bg-variant="light" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="assets_systems" href="javascript: void(0);">
                                    <i class="fas me-1" :class="collapse_states['assets_systems'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    Assets & Systems
                                </a>
                            </h6>
                    </b-card-header>
                    <b-collapse id="accordion-8" role="tabpanel" @show="handleCollapseAction('assets_systems')" @hidden="handleCollapseAction('assets_systems')">
                        <b-card-body v-if="loaded_tables.assets_systems == true">
                            <b-card-text>
                                <b-row>
                                    <div class="mt-1">
                                        <assetRegister v-if="loading_index.assets_systems >= 1" />
                                        <systems  v-if="loading_index.assets_systems >= 2"/>                                   
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>      
                </b-card>
                <b-card id="card-9" class="mb-1 shadow-none collapse-card">
                    <b-card-header v-b-toggle.accordion-9 header-bg-variant="light" header-tag="header" role="tab">
                            <h6 class="m-0">
                                <a class="text-dark" id="jobs_config" href="javascript: void(0);">
                                    <i class="fas me-1" :class="collapse_states['jobs_config'] == true ? 'fa-angle-up' : 'fa-angle-down'"></i>
                                    Job Profiles
                                </a>
                            </h6>
                    </b-card-header>
                    <b-collapse id="accordion-9" role="tabpanel" @show="handleCollapseAction('jobs_config')" @hidden="handleCollapseAction('jobs_config')">
                        <b-card-body v-if="loaded_tables.jobs_config == true">
                            <b-card-text>
                                <b-row>
                                    <div v-if="loading_index.jobs_config >= 4" class="mt-1">
                                        <JobsTab />                          
                                    </div>
                                </b-row>
                            </b-card-text>
                        </b-card-body>
                    </b-collapse>      
                </b-card>
                
        </div> -->
        <div v-show="toggleViewValue">
            <div>
                <navList />
            </div>
            <div class="structure-page structure-card">
                <div v-if="profilePageIndex == 1">
                    <values />
                </div>
                <div v-if="profilePageIndex == 2">
                    <workGroups :noEdit="false"/>
                    <workGroupMembers v-if="this.selected_team_id != -1" :noEdit="false" />
                </div>
                <div v-if="profilePageIndex == 3">
                    <processMap />
                </div>
                <div v-if="profilePageIndex == 4">
                    <policiesAndGuidelinesTable />
                </div>
                <div v-if="profilePageIndex == 5">
                    <processesAndProceduresTableVue />
                </div>
                <div v-if="profilePageIndex == 6">
                    <serviceProviders />
                    <communicationTools />
                </div>
                <div v-if="profilePageIndex == 7">
                    <frictionRegister />
                    <riskRegister />
                </div>
                <div v-if="profilePageIndex == 8">
                    <assetRegister />
                    <systems />  
                </div>
                <div v-if="profilePageIndex == 9">
                    <JobsTab /> 
                </div>


            </div>
        </div>
    </template>

<script>
import { levelComputed ,peopleComputed, peopleMethods, profileComputed, profileMethods, activityComputed, activityMethods, adminComputed, adminMethods, performanceComputed, performanceMethods } from '@/state/helpers'
import milestoneHelpers from '@/mixins/milestoneHelpers.js'
import workGroupMembers from './profile-tables/workGroupMembers.vue'
import serviceProviders from './profile-tables/serviceProviders.vue'
import communicationTools from './profile-tables/communicationTools.vue'
import policiesAndGuidelinesTable from './profile-tables/policiesAndGuidelines.vue'
import processesAndProceduresTableVue from './profile-tables/processesAndProcedures.vue'
//import proceduresTable from './profile-tables/procedures.vue'
// /import guidelinesTable from './profile-tables/guidelines.vue'
import systems from './profile-tables/systems.vue'
import frictionRegister from './profile-tables/frictionRegister.vue'
import riskRegister from './profile-tables/riskRegister.vue'
import assetRegister from './profile-tables/assetRegister.vue'
import workGroups from './profile-tables/workGroups.vue'
//import productAndService from './profile-tables/productAndService.vue'
import JobsTab from '@/components/people/jobs-tab.vue'
import values from './profile-tables/values.vue'
import processMap from './profile-tables/processMap.vue'
import navList from './profileComponents/navList.vue'
import _ from 'lodash'
//import riskMatrix from './profile-tables/riskMatrix.vue'
//import documentStructure from './profile-tables/documentStructure.vue'
export default {
    mixins:[ milestoneHelpers ],
    components: {
        workGroups,
        workGroupMembers,
        serviceProviders,
        communicationTools,
        policiesAndGuidelinesTable,
        processesAndProceduresTableVue,
        //proceduresTable,
        //guidelinesTable,
        systems,
        frictionRegister,
        riskRegister,
        assetRegister,
        values,
        JobsTab,
        processMap,
        navList
        //riskMatrix
        //documentStructure
    },
    props:{
        initial_table_mode:{
            default: 'view',
        },
    },
    watch: {
        current_level_id: {
            handler() {
                Promise.all([
                    this.loadTeams(this.current_level_id)
                ])
                .then(()=>{
                    this.$nextTick(()=>{
                        if(window.webix.$$('riskRegister'))
                        window.webix.$$('riskRegister').refresh();
                    })
                })
            }
        },
        profilePageIndex: {
            immediate: true,
            handler() {
                if(this.profilePageIndex != -1) {
                    let value = this.profilePageIndex
                    this.tab_index = Number(value)
                } 
            }    
        },
        // selected_org_level:{
        //     handler(){
        //         if(this.selected_org_level != null){
        //             this.loadActivityData(this.company_id)
        //             console.log("FIRING TWO");
        //            this.loadTeams(this.selected_org_level);
        //         }
        //     }
        // },
        pinned_milestone_id:{
            handler(){
                // -- this.loadActivityData(this.company_id)
                this.loadTeams(this.current_level_id);
            }
        },
        hiddenOrgChart() {
            if(window.webix.$$('values')) {
                window.webix.$$('values').refresh();
            }
        }
    },
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        ...activityComputed,
        ...adminComputed,
        ...performanceComputed
    },
    methods:{
        ...profileMethods,
        ...peopleMethods,
        ...activityMethods,
        ...adminMethods,
        ...performanceMethods,
        selectItem(item) {
            this.selectedItem = item.target.innerHTML

            if(item.target.innerHTML == 'Jobs Profiles') {
                this.tab_index = 8
            }

            switch(item.target.innerHTML) {
                case 'Jobs Profiles':
                    this.tab_index = 8
                    break;
                case 'Assets &amp; Systems':
                    this.tab_index = 7
                    break;
                case 'Friction &amp; Risks':
                    this.tab_index = 6
                    break;
                case 'Providers &amp; Tools':
                    this.tab_index = 5
                    break;
                case 'Processes &amp; Procedures':
                    this.tab_index = 4
                    break;
                case 'Policy &amp; Guidelines':
                    this.tab_index = 3
                    break;
                case 'Product &amp; Services':
                    this.tab_index = 2
                    break;
                default:
                    // code block
                } 
        },
        resizePage() {
            this.tab_menu = 0
            if (window.matchMedia("(max-width: 1525px)").matches) {
                this.tab_menu = 8;
            }
            if (window.matchMedia("(max-width: 1435px)").matches) {
                this.tab_menu = 7;
            }
            if (window.matchMedia("(max-width: 1300px)").matches) {
                this.tab_menu = 6;
            }
            if (window.matchMedia("(max-width: 1186px)").matches) {
                this.tab_menu = 5;
            }
            if (window.matchMedia("(max-width: 1053px)").matches) {
                this.tab_menu = 4;
            }
            if (window.matchMedia("(max-width: 645px)").matches) {
                this.tab_menu = 3;
            }
            if (window.matchMedia("(max-width: 505px)").matches) {
                this.tab_menu = 2;
            }
        },
        reloadGoalObjectveData(){
            return new Promise( (resolve, reject) => {
                let params = {
                    level_id: this.current_level_id,
                    milestone_data: 1,
                }
                this.loadPerformanceScreenData({
                    type: 'objective',
                    params: params,
                }).then(()=>{
                    resolve();
                    this.is_loading = false
                })
                .catch(()=>{
                    reject();
                })
            })
        },
        // toggleView() {
        //         this.toggleViewValue = true
        // },
        expandAll() {
                // this.collapse_states.map(item => {
                //     console.log(item);
                // })
                let states = this.collapse_states;
                for (const key in states) {
                    if(states[key] == false) {
                        console.log("Running for" + key);
                        var link = document.getElementById(key);
                        link.click();
                    }
                }
                this.all_expanded = true;
                console.log(this.collapse_states);
        },
        collapseAll() {
                let states = this.collapse_states;
                for (const key in states) {
                    if(states[key] == true) {
                        console.log("Running for" + key);
                        var link = document.getElementById(key);
                        link.click();
                    }
                }
                this.all_expanded = false;
        },
        handleCollapseAction(collapse_item){
            this.collapse_states[collapse_item] = !this.collapse_states[collapse_item];
            //if new state is true then refresh tables for the collapse
            if(this.collapse_states[collapse_item] == true){
                switch(collapse_item){
                case 'value':{
                    if(this.loaded_tables[collapse_item] == true) {
                        window.webix.$$('values').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true;
                    }
                    setTimeout(() => {
                        document.getElementById("card-1").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200)
                    break;
                }
                case 'work_groups':{
                    if(this.loaded_tables[collapse_item] == true) {
                        window.webix.$$('workGroups-structure').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true
                    }
                    setTimeout(() => {
                        document.getElementById("card-2").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200)
                    break;
                }
                case 'products_services':{
                    if(this.loaded_tables[collapse_item] == true) {
                        console.log("refreshing table 2");
                        window.webix.$$('productAndService').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true
                    }
                    setTimeout(() => {
                        document.getElementById("card-3").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200)
                    break;
                }
                case 'policy':{
                    if(this.loaded_tables[collapse_item] == true) {
                        window.webix.$$('policies').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true
                    }
                    setTimeout(() => {
                        this.loading_index.policy++
                    }, 150)
                    setTimeout(() => {
                        document.getElementById("card-4").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 200)
                    break;
                }
                case 'processes_procedures':{
                    if(this.loaded_tables[collapse_item] == true) {
                        window.webix.$$('processes').refresh();
                        window.webix.$$('procedures').refresh();
                        window.webix.$$('guidelines').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true
                    }
                    setTimeout(() => {
                        this.loading_index.processes_procedures++
                    }, 150)
                    setTimeout(() => {
                        this.loading_index.processes_procedures++
                    }, 300)
                    setTimeout(() => {
                        this.loading_index.processes_procedures++
                    }, 450)
                    setTimeout(() => {
                        document.getElementById("card-5").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 600)
                    break;
                }
                case 'tools_services':{
                    if(this.loaded_tables[collapse_item] == true) {
                        window.webix.$$('serviceProviders').refresh();
                        window.webix.$$('communicationTools').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true
                    }
                    setTimeout(() => {
                        this.loading_index.tools_services++
                    }, 150)
                    setTimeout(() => {
                        this.loading_index.tools_services++
                    }, 300)
                    setTimeout(() => {
                        document.getElementById("card-6").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 400)
                    break;
                }   
                case 'friction_risk':{
                    if(this.loaded_tables[collapse_item] == true) {
                        window.webix.$$('frictionRegister').refresh();
                        window.webix.$$('riskRegister').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true
                    }
                    setTimeout(() => {
                        this.loading_index.friction_risk++
                    }, 150)
                    setTimeout(() => {
                        this.loading_index.friction_risk++
                    }, 300)
                    setTimeout(() => {
                        document.getElementById("card-7").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 400)
                    break;
                }
                case 'assets_systems':{
                    if(this.loaded_tables[collapse_item] == true) {
                        window.webix.$$('assetRegister').refresh();
                        window.webix.$$('systems').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true
                    }
                    setTimeout(() => {
                        this.loading_index.assets_systems++
                    }, 150)
                    setTimeout(() => {
                        this.loading_index.assets_systems++
                    }, 300)
                    setTimeout(() => {
                        document.getElementById("card-8").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 400)
                    break;
                }
                case 'jobs_config':{
                    if(this.loaded_tables[collapse_item] == true) {
                        // window.webix.$$('assetRegister').refresh();
                        window.webix.$$('jobsTable').refresh();
                        window.webix.$$('jobSkillsTable').refresh();
                        window.webix.$$('onboardingTable').refresh();
                        window.webix.$$('offboardingTable').refresh();
                    } else {
                        this.loaded_tables[collapse_item] = true
                    }
                    setTimeout(() => {
                        this.loading_index.jobs_config++
                    }, 150)
                    setTimeout(() => {
                        this.loading_index.jobs_config++
                    }, 300)
                    setTimeout(() => {
                        this.loading_index.jobs_config++
                    }, 450)
                    setTimeout(() => {
                        this.loading_index.jobs_config++
                    }, 600)
                    setTimeout(() => {
                        document.getElementById("card-9").scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                    }, 800)
                    break;
                }  
                }
            }
        }
    },
    data:()=>({
        id: 0,
        loading_data: false,
        is_loading: false,
        loadingIndex: 0,
        active_collapse_index: null,
        all_expanded: false,
        toggleViewValue: true,
        collapse_states:{
            value: false, 
            work_groups: false,
            tools_services: false,
            policy: false,
            processes_procedures: false,
            friction_risk: false,
            products_services: false,
            assets_systems: false,
            jobs_config: false,
        },
        loaded_tables:{
            value: false,
            work_groups: false,
            tools_services: false,
            policy: false,
            processes_procedures: false,
            friction_risk: false,
            products_services: false,
            assets_systems: false,
            jobs_config: false,
        },
        loading_index: {
            tools_services: 0,
            processes_procedures: 0,
            friction_risk: 0,
            assets_systems: 0,
            jobs_config: 0
        }, 
        tab_menu: 0,
        selectedItem: '',
        tab_index: -1,
    }),
    beforeDestroy() {
        this.changeProfileTabIndex(1)
    },
    mounted(){
        this.loading_data = true
        if(this.companyJobs == null) {
            this.loadSkillCategories()
        }
        // eslint-disable-next-line vue/no-mutating-props
        this.loadSkillCategories()
        this.resizePage()
        this.id = this.current_level_id;
        console.log(this.levelEmployeesData)
        if(this.levelEmployeesData.length == 0) {
            this.loadEmployeesData(this.selected_level.id).then(() => {
                this.reloadGoalObjectveData()
            }) 
        }
        if(this.teams.length == 0) {
            this.loadTeams(this.selected_level.id).then(() => {
                this.loading_data = false
            });
        } else {
            this.loading_data = false
        }
        
        let el = this
        this.webix_resize_event = window.webix.event(window, "resize", _.debounce(function(){
            el.resizePage()
        }, 100));
        
    }
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}

.structure-page {
    flex-wrap: nowrap !important;
}

.structure-card {
    font-size: 0.7rem !important;
   
}

@media (max-width: 1525px) {
    .structure-page #job-profiles___BV_tab_button__ {
        visibility: none !important;
    }
}

@media (max-width: 1435px) {
    .structure-page #assets-systems___BV_tab_button__ {
        visibility: none !important;
    }
}

@media (max-width: 1300px) {
    .structure-page #friction-risk___BV_tab_button__ {
        visibility: none !important
    }
}

@media (max-width: 1186px) {
    .structure-page #provider-tools___BV_tab_button__ {
        display: none !important
    }
}

@media (max-width: 1053px) {
    .structure-page #processes-procedures___BV_tab_button__ {
        visibility: none !important
    }
}

@media (max-width: 645px) {
    .structure-page #policy-guidelines___BV_tab_button__ {
        visibility: none !important
    }
}

@media (max-width: 505px) {
    .structure-page #product-service___BV_tab_button__ {
        visibility: none !important
    }
}

.dropdown-toggle::after {
    display:none;
}

.item-styles {
    font-size: 0.7rem;
    font-weight: 500;
}

/* #header-dropdown:hover {
    background-color: #dcedfc !important
} */

#header-dropdown .dropdown-item:hover {
    background-color: #dcedfc !important
}

#header-dropdown .dropdown-menu {
    margin-top: 0.8px !important
}

.collapse-card:hover{ cursor:pointer}
</style>