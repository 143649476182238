<template>
    <b-modal id="addRolesModal" :title="params.id == -1 ? 'Add Role' : 'Update Role'" size="lg" hide-footer>
        <b-form @submit.prevent>
            <b-row>
                <b-col cols="12">
                    <b-row class="mb-1">
                        <b-col cols="2">
                            <label class="col-form-label" for="text">Name:</label>
                        </b-col>
                        <b-col cols="10">
                            <b-form-input required v-model="params.name"></b-form-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="2">
                            <label class="col-form-label" for="text">Description:</label>
                        </b-col>
                        <b-col cols="10">
                            <b-form-textarea required style="height: 150px" v-model="params.description"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <div class="text-end pt-2 mt-3">
                <b-button variant="light" @click="$bvModal.hide('addRolesModal')">Close</b-button>
                <b-button @click="save()" variant="success" class="ms-1">Save</b-button>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
import { levelComputed, peopleMethods, peopleComputed } from "@/state/helpers";
export default {
    props:{
        payload:{
            require: false
        },
    },
    data:() => ({
        params:{
            id: -1,
            name:'',
            description:''
        },
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
    },
    methods:{
        ...peopleMethods,
        save(){
            this.$emit('saveTable', this.params);
            this.$nextTick(() => {
                this.$bvModal.hide('addRolesModal');
            })
        }
    },
    beforeDestroy() {
        this.modal_payload = {}
    },
    mounted(){
        console.log(this.payload)
        if(this.payload.id) {
            this.params.id = this.payload.id
            this.params.name = this.payload.name
            this.params.description = this.payload.description
        }
        // if(this.type == 'child'){
        //     this.params.parent_id = this.parent_id;
        // }
        // this.params.level_id = this.selected_level.id;
    },
}
</script>

<style>

</style>