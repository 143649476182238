<template>
    <b-modal id="systemModal" title="Add System" size="lg" hide-footer>
        <b-form @submit.prevent>
            <div class="container">
                <div class="row">
                    <div class="col-sm p-0">
                        <b-form-group class="label" id="input-group-1" label="Vendor Name:" label-for="input-1" size="sm">
                            <b-form-input
                            id="input-1"
                            size="sm"
                            v-model="params.vendor_name"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-sm p-0" style="margin-left: 4px;">
                        <b-form-group class="label" id="input-group-2" label="System Name:" label-for="input-2" size="sm">
                            <b-form-input
                            id="input-2"
                            size="sm"
                            v-model="params.name"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
            </div>
           
            <div class="container">
                <div class="row">
                    <div class="col-sm p-0">
                        <b-form-group id="input-group-3" label="Organisation Structure:" label-for="input-3" size="sm" >
                        <b-row>
                            <b-col cols="8" style="margin-right: 5px; padding-right: 0px; height:100%; width: 85%">
                                <b-form-input
                                    id="input-3"
                                    size="sm"
                                    v-model="getLevelForID"
                                    placeholder="Select Organisation Structure"
                                    required
                                    readonly
                                ></b-form-input>
                            </b-col>
                            <b-col cols="4" style="display:flex; justify-content: center; padding: 0; margin: 0; width: auto">
                                <b-button variant="primary" size="sm" @click="showHierarchyModal(params)">
                                    <i class="fas fa-pen"></i>
                                </b-button>
                            </b-col> 
                        </b-row>
                    </b-form-group>
                    </div>
                    <div class="col-sm p-0" style="margin-left: 4px;">
                        <b-form-group class="label" id="input-group-2" label="Functionality:" label-for="input-2" size="sm">
                            <b-form-input
                            id="input-2"
                            size="sm"
                            v-model="params.functionality"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-sm p-0">
                        <b-form-group id="input-group-4" label="Active Date:" label-for="input-4" size="sm" >
                            <b-form-input
                            id="input-4"
                            size="sm"
                            type="date"
                            v-model="params.active_date"
                            placeholder="Active Date"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-sm p-0" style="margin-left: 4px;">
                        <b-form-group id="input-group-4" label="Expiry Date:" label-for="input-4" size="sm" >
                            <b-form-input
                            id="input-4"
                            size="sm"
                            type="date"
                            v-model="params.expiry_date"
                            placeholder="Expiry Date"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="text-end pt-2 mt-3">
                <b-button variant="light" @click="$bvModal.hide('systemModal')">Close</b-button>
                <b-button @click="save()" variant="success" class="ms-1">Save</b-button>
            </div>
        </b-form>
        <HierarchyModal @selectedNewID="handleLevelUpdateEvent" :payload="modal_payload" show_all_levels v-if="show_level_linking_modal" />
    </b-modal>
</template>

<script>
import { levelComputed, peopleMethods, peopleComputed } from "@/state/helpers";
import HierarchyModal from '@/components/objectives/components/misc/hierarchyModal.vue'
import {parseISO, format} from 'date-fns'
import _ from 'lodash'
// /import _ from 'lodash'
export default {
    components:{
        HierarchyModal,
    },
    props:{
        payload:{
            require: false
        },
    },
    data:() => ({
        params:{
            id: 0,
            name:'',
            level_id: null,
            serial_no: '',
            expiry_date: '',
            modal_type: 'objective'
        },
        modal_payload: {},
        show_level_linking_modal: false
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        getLevelForID(){
            let level = this.levels.find( level => {
                return level.id == this.params.level_id
            });
            if(level) {
                return level.name
            } 
            return 'No Level Linked'
        },
    },
    methods:{
        ...peopleMethods,
        handleLevelUpdateEvent(new_id) {
            this.params.level_id = new_id;
            this.$bvModal.hide('hierarchyModal')
        },
        showHierarchyModal(payload){
            this.modal_payload = _.cloneDeep(payload);
            console.log(this.modal_payload)
            let level_id = 0
            if(payload.level_id == undefined) {
                level_id = this.selected_level.id
            } else {
                level_id = payload.level_id
            }
            let level = this.levels.find((lvl) => lvl.id == level_id);
            this.modal_payload = { id: payload.level_id, parent_id: level.parent_id, item_id: payload.id, level_id: payload.level_id};
            this.modal_payload['modal_type'] = 'Objective'
            this.show_level_linking_modal = true;
            setTimeout(()=>{
                this.$bvModal.show('hierarchyModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "hierarchyModal") {
                        this.show_level_linking_modal = false;
                        this.modal_payload = null;
                    }
                });
            }, 0)
        },
        // showHierarchyModal() {
        //     this.modal_payload = this.params;
        //     let level = this.levels.find( level => {
        //         return level.id == this.params.level_id
        //     });
        //     if(level.parent_id) {
        //         this.modal_payload.parent_id = level.parent_id
        //     } else {
        //         this.modal_payload.parent_id = null
        //     }
        //     this.show_hierarchy_modal = true;
        //     setTimeout(()=>{
        //         this.$bvModal.show('levelLinkingModal');
        //         this.$root.$once("bv::modal::hidden", (event) => {
        //             if (event.type == "hidden" && event.componentId == "levelLinkingModal") {
        //                 this.show_hierarchy_modal = false;
        //             }
        //         });
        //     }, 1)
        // },
        save(){
            this.$emit('saveTable', [this.params]);
            this.$nextTick(() => {
                this.$bvModal.hide('systemModal');
            })
        }
    },
    beforeDestroy() {
        this.modal_payload = {}
    },
    mounted(){
        console.log(JSON.parse(JSON.stringify(this.payload)))
        if(this.payload.id) {
            let modified_active_date = new Date(this.payload.active_date)
            let modified_expiry_date = new Date(this.payload.expiry_date)
            this.params.id = this.payload.id
            this.params.vendor_name = this.payload.vendor_name
            this.params.name = this.payload.name
            this.params.functionality = this.payload.functionality
            this.params.active_date = format(parseISO(modified_active_date.toISOString()), 'yyyy-MM-dd')
            this.params.expiry_date = format(parseISO(modified_expiry_date.toISOString()), 'yyyy-MM-dd')
            this.params.level_id = this.payload.level_id;
        } else {
            this.params.level_id = this.selected_level.id;
        }
    },
}
</script>

<style>

</style>