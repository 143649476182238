<template>
    <b-modal id="addValueModal" centered title="Add Value" size="lg" hide-footer>
        <b-form @submit.prevent>
            <b-row>
                <b-col cols="12">
                    <b-row class="mb-1">
                        <b-col cols="2">
                            <label class="col-form-label" for="text">Value:</label>
                        </b-col>
                        <b-col cols="10">
                            <b-form-input v-model="params.value"></b-form-input>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="2">
                            <label class="col-form-label" for="text">How:</label>
                        </b-col>
                        <b-col cols="10">
                            <b-form-textarea v-model="params.how" style="height: 150px;"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <div class="text-end pt-2 mt-3">
                <b-button variant="light" @click="$bvModal.hide('addValueModal')">Close</b-button>
                <b-button @click="save()" variant="success" class="ms-1">Save</b-button>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
import { levelComputed, peopleMethods, peopleComputed } from "@/state/helpers";
export default {
    props:{
        payload:{
            require: false
        },
    },
    data:() => ({
        params:{
            id: -1,
            value:'',
            how:''
        },
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
    },
    methods:{
        ...peopleMethods,
        save(){
            this.$emit('saveTable', this.params);
            this.$nextTick(() => {
                this.$bvModal.hide('addValueModal');
            })
        }
    },
    beforeDestroy() {
        this.modal_payload = {}
    },
    mounted(){
        if(this.payload.id) {
            this.params.id = this.payload.id
            this.params.value = this.payload.value
            this.params.how = this.payload.how
        }
        // if(this.type == 'child'){
        //     this.params.parent_id = this.parent_id;
        // }
        // this.params.level_id = this.selected_level.id;
    },
}
</script>

<style>

</style>