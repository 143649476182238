
  <template>
     <span class="NavItem d-flex" @click="selectItem(kpi.id)" :style="profilePageIndex == kpi.id ? 'justify-content: center; border-bottom: 2px solid #8881B3;' : 'justify-content: center;'" :data-index="index">
        <span class="kpi_text" :style="kpi.seen == false ? 'visibility: hidden; margin: 2px' : 'margin: 2px'">
          {{kpi.text}}
        </span>
      </span>
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
import { profileComputed, profileMethods } from '@/state/helpers'
export default {
    name: 'NavItems',
    mixins: [ treeComponentMixin ],
    props: ["kpi", "observer", "index"],
    data:() => ({  
      tab_index: 1,
      is_loading: false
    }),
    computed:{
      ...profileComputed
    },
    methods:{
      ...profileMethods,
      selectItem(item) {
          this.is_loading = true
          this.changeProfileTabIndex(item).then(() => {
              this.is_loading = false
          })
      },
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted(){
        this.observer.observe(this.$el);
    },
}
</script>


<style>
.NavItem {
    display: inline-flex;
    margin-left: 5px;
    margin-right: 5px;
    width: 158px;
    scroll-snap-align: start;
}

.NavItem:first-child {
  scroll-snap-align: end;
  margin-left: 1px !important
}

.NavItem:last-child {
  scroll-snap-align: end;
  margin-right: 1px !important
}

</style>