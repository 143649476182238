<template>
    <b-row class="mt-2">
        <b-col cols="12">
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :ref="table_uid"
                    :data="systems_data"
                    :is_loading="is_loading"
                    :pager_config=10
                    :default_values="default_values"
                    @saveTable="saveTable"
                    :validation="table_validation_rules"
                    @updateViewMode="updateViewMode"
                    @deleteRows="deleteRow"
                    :button_methods="button_methods"
                    hide_action_buttons
                    no_extra_rows
                    :edit_permissions="{manager:'assets-systems'}"
                    :required_permissions="['assets-systems']"
                    guideline_key="systems_table"
                >
                    <template #title>
                        Systems
                    </template>
                    <template #buttons>  
                        <b-button size="sm" variant="primary" @click="showSystemModal({})" class="me-1" style="display: inline-block">
                            <font-awesome-icon :icon="['fas', 'fa-plus']" />
                        </b-button>
                    </template>
                </Table>
        </b-col>
        <systemModal :payload="modal_payload" v-if="show_system_modal" @saveTable="saveTable" />
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { levelComputed ,peopleComputed, profileComputed, profileMethods, } from '@/state/helpers'
import Swal from 'sweetalert2'
import systemModal from './profileModals/systemModal.vue'
import dateMixin from '@/mixins/dateMixin.js'
import { format, parseISO } from 'date-fns';

import _ from 'lodash';

export default {
    mixins:[ dateMixin ],
    name: 'systems',
    components: {
        Table,
        systemModal
    },
    props:{
        initial_table_mode:{
            default: 'view',
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'systems',
        table_view_mode: 'view',
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        show_system_modal: false,
        modal_payload: {}
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'edit_action':function(e, id){
                    // let reminder = el.meeting_data.reminders.find(item => item.id == id);
                    //console.log(id)
                    let item = this.getItem(id)
                    el.showSystemModal(item)
                },
            }
            return onClick;
        },
        systems_data(){
            if(this.teams.systems == undefined){
                return [];
            }
            else{
                return this.teams.systems;
            }
        },
        default_values(){
            let el = this;
            return [
                { id : "level_id", value: el.selected_level.id },
            ]
        },
        //* table computed methods
        columns(){
            //* get ref of current scope
            var el = this;
            let systems_columns = [
                { id: 'vendor_name', header: 'Vendor Name', fillspace: 2, editor: 'text' },
                { id: 'name', header: 'System Name', fillspace: 2, editor: 'text' },
                { id: 'level_id', header: 'Linked Level', fillspace: 3,
                    template:function(obj){
                        if(obj.level_id != undefined){
                            let index = _.findIndex(el.levels, {id: obj.level_id });
                            let level_name = '';
                            if(index != -1){
                                level_name = el.levels[index].name;
                            }
                            //if(el.table_view_mode === 'edit'){
                            //    //* we only need to show the pencil icon in edit mode
                            //    return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>${level_name}</span>`
                            //}
                            //else{
                                return `<span>${level_name}</span>`
                            //}
                        }
                        else{
                            return ''
                        }
                    },
                },
                { id: 'functionality', header: 'Functionality', fillspace: 2, editor: 'text' },
                { id: 'active_date', header: 'Active Date', fillspace: 1, editor: 'date',
                    //* example on how to format date without mutating the origional data
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
                { id: 'expiry_date', header: 'Expiry Date', fillspace: 1, editor: 'date',
                    //* example on how to format date without mutating the origional data
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
            ];
            return systems_columns;
        },
        table_validation_rules(){
            let el = this;
            return {
                "vendor_name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "functionality": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "active_date": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            if(obj.active_date && obj.expiry_date){
                                return el.startDateCheck(obj.active_date, obj.expiry_date)
                            }
                            return true;
                        }
                    }
                },
                "expiry_date": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item == '' || item == undefined){
                            return false;
                        }
                        else{
                            if(obj.active_date && obj.expiry_date){
                                return el.endDateCheck(obj.expiry_date, obj.active_date)
                            }
                            return true;
                        }
                    }
                },
            };
        },
    },
    methods:{
        ...profileMethods,
        //* table event handlers
        updateViewMode(mode){
            this.table_view_mode = mode;
        },
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            this.active_id = row.id;
        },
        saveTable(data){
            this.is_loading = true;
            let level_systems = [];
            data.forEach(( row ) =>{
                let save_row = {
                    id: row.is_new ? -1 : row.id,
                    level_id: row.level_id,
                    vendor_name: row.vendor_name,
                    name: row.name,
                    functionality: row.functionality,
                    active_date: row.active_date,
                    expiry_date: row.expiry_date,
                }
                level_systems.push(save_row);
            })
            this.saveSystems({ level_systems : level_systems })
            .then(()=>{
                this.loadTeams(this.selected_level.id)
                .then(()=>{
                    this.$refs[this.table_uid].table_mode = 'view';
                    this.is_loading = false;
                })
            })
            .catch(()=>{
                this.is_loading = false;
            })
        },
        deleteRow(data) {
            let promises = [];
            this.is_loading = true;
            data.forEach( item => {
                promises.push(this.deleteSystems({id: item.id}));
            })
            Promise.all(promises)
            .then(()=>{
                this.loadTeams(this.selected_level.id)
                .then(()=>{
                    this.$refs[this.table_uid].table_mode = 'view';
                    this.is_loading = false;
                })
			})
            .catch(()=>{
                this.is_loading = false;
            })
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        showSystemModal(item){
            this.modal_payload = _.cloneDeep(item);
            this.show_system_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('systemModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "systemModal") {
                        this.show_system_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
    },
    beforeMount(){
        this.table_view_mode = this.initial_table_mode;
    },
    mounted(){},
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
</style>