
<template>
    <div class="d-flex mt-3" style="flex-direction: column; align-items: center;">
        <!-- <div class="my-auto mx-auto" style="display: inline-flex; max-height: 35px;">
            <span v-show="!leftMax" class="me-2 mt-2 cursor-pointer"><i @click="scroll(false)" class="carousel-arrow left arrow_styles" style="height: 13px; width: 13px; user-select: none;"></i></span>
            <ul v-for="element of items" :key="element.id" style="display: inline-flex; font-size: 1.1rem; user-select: none; padding-left: 1.5rem; padding-bottom: 0px !important;">
                <li :class="element.seen ? 'purple_dark' : 'light_grey'" style="user-select: none;" ></li>
            </ul>
            <span v-show="!rightMax" class="ms-2 mt-2 cursor-pointer"><i @click="scroll(true)" class="carousel-arrow right arrow_styles" style="height: 13px; width: 13px; user-select: none;"></i></span>
        </div> -->
        <div class="w-100" style="display: inline-flex" >
            
        <span v-show="!leftMax" style="min-width: 70px; border-bottom: 1px solid #BFC8D6; max-height: 25px;">
            <span class="">
                <span class="me-2 mt-2 cursor-pointer"><font-awesome-icon :icon="['fas', 'angle-left']" @click="scroll(false)" style="height: 12px !important; width: 12px !important; color: #50A5F1" /></span>
                {{ leftHiddenCount }}  More
            </span>
        </span>
        
        <div id="scrollContainer" class="nav-list mb-2">
        <NavItem
            v-for="(item, i) in items"
            :key="item.id"
            :kpi="item"
            :observer="observer"
            :index="i"
            class="cursor-pointer"
            :style="item.seen == false ? 'visibility: hidden; text-decoration: none' : 'text-decoration: none'"
        ></NavItem>
        </div>
        <span v-show="!rightMax && rightHiddenCount != 9" style="min-width: 70px; border-bottom: 1px solid #BFC8D6; max-height: 25px;">
            <span>
                {{ rightHiddenCount }} More
                <span class="ms-2 mt-2 cursor-pointer"><font-awesome-icon :icon="['fas', 'angle-right']" @click="scroll(true)" style="height: 12px !important; width: 12px !important; color: #50A5F1" /></span>
            </span>
        </span>
        <!-- {{ inViewItemId }} -->
        </div>
        <!-- {{ inViewItemId }} -->
    </div>
    
</template>

<script>
import treeComponentMixin from '@/mixins/treeComponentMixin.js'
import NavItem from './navItem.vue'
export default {
name: 'navList',
mixins: [ treeComponentMixin ],
components: {
  NavItem
},
data:() => ({
    items: [
        { id: 1, seen: false, text: "Values" },
        { id: 2, seen: false, text: "Work Groups" },
        { id: 3, seen: false, text: "Products & Services" },
        { id: 4, seen: false, text: "Policy & Guidelines" },
        { id: 5, seen: false, text: "Processes & Procedures" },
        { id: 6, seen: false, text: "Providers & Tools" },
        { id: 7, seen: false, text: "Friction & Risks" },
        { id: 8, seen: false, text: "Assets & Systems" },
        { id: 9, seen: false, text: "Job Profiles" }
    ],
    observer: null,
    //leftMax: false,
    // rightMax: false,
    loading: false,
    isDown: false,
    inViewItemId: 0
}),
// watch : {
//     leftHiddenCount() {
//         this.loading = true
//         //setTimeout(() => {
//             if(this.leftHiddenCount == 0) {
//                 this.leftMax = true
//             } else {
//                 this.leftMax = false
//             }
//             this.loading = false
//         //}, 100)
        
//     },
//     rightHiddenCount() {
//         this.loading = true
//         //setTimeout(() => {
//             if(this.rightHiddenCount == 0) {
//                 this.rightMax = true
//             } else {
//                 this.rightMax = false
//             }
//             this.loading = false
//         //}, 1000)
        
//     }
// },
computed:{
    leftMax() {
        if(this.leftHiddenCount == 0) {
            return true
        } else {
            return false
        }
    },
    rightMax() {
        if(this.rightHiddenCount == 0) {
            return true
        } else {
            return false
        }
    },
    checkPosition() {
        let element = document.getElementById('scrollContainer')
        if(element && element.scrollLeft != 0) {
            return true
        } else {
            return false
        }
    },
    leftHiddenCount() {
        let count = 0
        this.items.forEach(item => {
            if(item.seen == false && item.id <= this.inViewItemId) {
                count++
            }
        })
        return count
    },
    rightHiddenCount() {
        let count = 0
        this.items.forEach(item => {
            if(item.seen == false && item.id >= this.inViewItemId) {
                count++
            }
        })
        return count
    }
},
methods:{
   onElementObserved(entries) {
        entries.forEach(({ target, isIntersecting}) => {
                console.log(target)
                console.log(isIntersecting)
               
            if (!isIntersecting) {
                    setTimeout(() => {
                        const i = target.getAttribute("data-index");
                        if(i) {
                            this.items[i].seen = false;
                            this.inViewItemId = this.inViewItemId - 1
                        }
                    }, 50)
                    return
            }
            if (isIntersecting) {
                    setTimeout(() => {
                        const i = target.getAttribute("data-index");
                        if(i) {
                            this.items[i].seen = true;
                            this.inViewItemId = this.items[i].id
                        }
                        
                    }, 50)
                    return
                }
            this.observer.unobserve(target);
        });
    },
    scroll(right) {
        if(!this.loading) {
            
            this.loading = true
            console.log("Running here")
            let value = document.getElementById('scrollContainer')
            console.log(value)
            let scrollValue = value.scrollLeft
            //let maxValue = value.scrollLeftMax
            //value.scrollLeft = scrollValue + 100;

            let calc = 0
            if(right) {
                calc = scrollValue + 185
            } else {
                calc = scrollValue - 185
            }
            // if(calc <= 0) {
            //     this.leftMax = true
            // } else {
            //     this.leftMax = false
            // }
            // if(calc >= maxValue) {
            //     this.rightMax = true
            // } else {
            //     this.rightMax = false
            // }
            value.scrollTo({
                top: 100,
                left: calc,
                behavior: "smooth",
            });
            setTimeout(() => {
                this.loading = false
            }, 500)
        }
    },
},
created() {
   //eslint-disable-next-line vue/no-mutating-props
   this.observer = new IntersectionObserver(
       this.onElementObserved,
       {
           root: this.$el,
           threshold: 1,
       }
   );
},
beforeDestroy() {
 this.observer.disconnect();
},
mounted(){
    this.observer.observe(this.$el);
    let parent = document.getElementById('scrollContainer')
    parent.addEventListener("wheel", (evt) => {
            evt.preventDefault();
            parent.scrollLeft += 205;
    });

    //const slider = document.querySelector('.items');
    let isDown = false;
    let startX;
    let scrollLeft;

    parent.addEventListener('mousedown', (e) => {
        isDown = true;
        parent.classList.add('active');
        startX = e.pageX - parent.offsetLeft;
        scrollLeft = parent.scrollLeft;
    });
    parent.addEventListener('mouseleave', () => {
        isDown = false;
        parent.classList.remove('active');
    });
    parent.addEventListener('mouseup', () => {
        isDown = false;
        parent.classList.remove('active');
    });
    parent.addEventListener('mousemove', (e) => {
        if(!isDown) return;
        e.preventDefault();
        const x = e.pageX - parent.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        parent.scrollLeft = scrollLeft - walk;
        console.log(walk);
    });


    let element = document.getElementById('scrollContainer')
    console.log(element)
},
}
</script>
<style>
.nav-list {
display: flex;
flex-wrap: wrap;
flex-direction: column;
overflow: auto;
overflow-x: hidden;
height: 25px;
width: 100%;
scroll-behavior: smooth;
scroll-snap-type: x mandatory !important;
border-bottom: 1px solid #BFC8D6;
}

.carousel-arrow {
  border: 10px solid #8881B3;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

</style>