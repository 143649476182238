<template>
    <b-row>
        <b-col cols="12">
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :ref="table_uid"
                    :default_values="default_values"
                    :data="asset_data"
                    :is_loading="is_loading"
                    :pager_config=10
                    :button_methods="button_methods"
                    @selectedRowEvent="selectedRowEvent"
                    :validation="table_validation_rules"
                    @saveTable="saveTable"
                    @deleteRows="deleteRow"
                    @updateViewMode="updateViewMode"
                    hide_action_buttons
                    no_extra_rows
                    :edit_permissions="{manager:'assets-systems'}"
                    :required_permissions="['assets-systems']"
                    guideline_key="asset_register_table"
                >
                    <template #title>
                        Asset Register
                    </template>
                    <template #buttons>  
                        <b-button size="sm" variant="primary" @click="assetModal({})" class="me-1" style="display: inline-block">
                            <font-awesome-icon :icon="['fas', 'fa-plus']" />
                        </b-button>
                    </template>
                </Table>
        </b-col>
        <HierarchyModal v-if="show_level_linking_modal" show_all_levels :payload="modal_payload" @selectedNewID="handleRowLevelsUpdate" @updatedNewID="handleRowLevelsUpdate"/>
        <assetModal :payload="modal_payload" v-if="show_asset_modal" @saveTable="saveTable" />
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { levelComputed ,peopleComputed, profileComputed, profileMethods } from '@/state/helpers'
import HierarchyModal from '@/components/objectives/components/misc/hierarchyModal.vue'
import assetModal from './profileModals/assetModal.vue'
import Swal from 'sweetalert2'
import _ from 'lodash'

import { format, parseISO } from 'date-fns';
export default {
    name: 'assetRegister',
    components: {
        Table,
        HierarchyModal,
        assetModal
    },
    props:{
        initial_table_mode:{
            default: 'view',
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'assetRegister',
        show_level_linking_modal: false,
        table_view_mode: 'view',
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        modal_payload: {},
        show_asset_modal: false
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'edit_action':function(e, id){
                    let item = this.getItem(id)
                    el.assetModal(item)
                },
            }
            return onClick;
        },
        //* table computed methods
        columns(){
            //* get ref of current scope
            var el = this;
            let asset_columns = [
                    { id: 'asset_name', header: 'Asset Name', editor: 'text', fillspace: 1,},
                    {
                        id: "level_id",
                        //editor: "combo",
                        header: 'Linked Level',
                        sort: "text",
                        width: 250,
                        template:function(obj){
                            if(obj.level_id != undefined){
                                let index = _.findIndex(el.levels, {id: obj.level_id });
                                let level_name = '';
                                if(index != -1){
                                    level_name = el.levels[index].name;
                                }

                                if(level_name != '') {
                                    return `<span>${level_name}</span>`
                                }
                            }
                            else{
                                return ''
                            }
                        },
                    },
                    { id: 'serial_no', header: 'Serial Number', fillspace: 1, editor: 'text' },
                    { id: 'purchased_date', header: 'Purchased Date', fillspace: 1, editor: 'date',
                        format:function(value){
                            let date = value;
                            if(date !== ''){
                                if(typeof date === 'string'){
                                    date = parseISO(date);
                                }
                                return format(date, 'dd-MMM-yyyy');
                            }
                            else{
                                return '';
                            }
                        }
                    }
            ];
            return asset_columns;
        },
        asset_data(){
            if(this.teams.assets == undefined){
                return [];
            }
            else{
                return this.teams.assets;
            }
        },
        default_values(){
            let el = this;
            return [
                { id : "level_id", value: el.selected_level.id },
            ]
        },
        table_validation_rules(){
            return {
                "asset_name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "serial_no": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "purchased_date": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            };
        },
    },
    methods:{
        ...profileMethods,
        assetModal(item){
            this.modal_payload = _.cloneDeep(item);
            this.show_asset_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('assetModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "assetModal") {
                        this.show_asset_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        //* table event handlers
        updateViewMode(mode){
            this.table_view_mode = mode;
        },
        handleRowLevelsUpdate(payload){
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.item_id);
            item['level_id'] = Number(payload);
            table.updateItem(this.modal_payload.item_id, item)
            this.$bvModal.hide('hierarchyModal');
        },
        showHierarchyModal(payload){
            this.modal_payload = _.cloneDeep(payload);
            console.log(this.modal_payload)
            let level_id = 0
            if(payload.level_id == undefined) {
                level_id = this.selected_level.id
            } else {
                level_id = payload.level_id
            }
            let level = this.levels.find((lvl) => lvl.id == level_id);
            console.log(level)
            this.modal_payload = { id: payload.level_id, parent_id: level.parent_id, item_id: payload.id, level_id: payload.level_id};
            this.modal_payload['modal_type'] = 'Objective'
            this.show_level_linking_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('hierarchyModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "hierarchyModal") {
                        this.show_level_linking_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            if(row !== null){
                this.active_id = row.id;
            } else {
                this.active_id = -1
            }
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        saveTable(data){
            console.log(JSON.parse(JSON.stringify(data)));
            this.is_loading = true;
            let rows_to_save = [];
            data.forEach( row => {
                if(!row.is_empty){
                    let newRow = row.is_new;
                    var params = {
                        id: newRow ? 0 : row.id,
                        asset_name: row.asset_name,
                        level_id: row.level_id,
                        serial_no: row.serial_no,
                        purchased_date: row.purchased_date
                    };
                    rows_to_save.push(params);
                }
            })

            let params = {
                level_assets: rows_to_save,
            }
            this.is_loading = true;

            console.log(params);
            this.saveLevelAssets(params).then(()=>{
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view'
                    })
                })
        },
        deleteRow(data) {
            let promises = [];
            this.is_loading = true;
            data.forEach( item => {
                promises.push(this.deleteLevelAssets({id: item.id}));
            })
            Promise.all(promises)
            .then(()=>{
                this.loadTeams(this.selected_level.id)
                .then(()=>{
                    this.$refs[this.table_uid].table_mode = 'view';
                    this.is_loading = false;
                })
			})
            .catch(()=>{
                this.is_loading = false;
            })
        },
    },
    beforeMount(){
        this.table_view_mode = this.initial_table_mode;
    },
    mounted(){},
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
</style>