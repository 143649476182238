<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-14T11:24:47+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T08:05:23+02:00
@Copyright: Diversitude 2021
-->

<template>
    <div class="job_container m-2">
        <div style="">
            <span class="d-flex h-100" style="height:250px" >
                <b-col class="col-5 d-flex ms-3" style="flex-direction: column; min-height:280px">
                     <span v-if="jobDetailsEditMode || edit_mode" class="mt-2 mb-1">
                        Job Name
                        <b-form-input id="jobNameInput" @focusout="saveJob" v-model="name" class="mt-1 mb-2" placeholder="Enter Job Name"></b-form-input>
                     </span>
                     <span v-if="!(jobDetailsEditMode || edit_mode)" class="mt-2 mb-1 ms-1" style="color: #6E84A7; font-size: 22px;">
                        {{ levelJob.title }}
                     </span>
                     <div class="mt-2 mb-1">
                        <span class="m-2">
                            Job Summary 
                        </span>
                        <b-badge v-if="saving && edit_mode" class="bg-success bg-soft font-size-12 text-dark d-flex" style="float: right; vertical-align: middle; margin-top: 0.1rem">
                            <div style="display: inline-flex">
                                <b-spinner small class="me-1"></b-spinner>
                                <div style="margin-top: 0.2em">Saving...</div>
                            </div>
                        </b-badge>
                        <b-badge v-if="!saving && edit_mode" class="bg-success bg-soft font-size-12 text-dark d-flex" style="float: right; vertical-align: middle; margin-top: 0.2rem">
                            Saved
                        </b-badge>
                     </div>
                    
                    <!-- <b-form-textarea class="h-100 mb-2" v-model="value" /> -->
                    <div v-if="body_data == '' && !edit_mode" style="height: 204px">
                        <b-alert show>No Job Summary</b-alert>
                    </div>
                    <div v-if="body_data && !edit_mode" style="height: 204px">
                        <!--<b-form-textarea
                        readonly
                        v-model="body_text"
                        no-resize
                        rows="7"
                        ></b-form-textarea>-->
                        <blockquote class="p-1 mb-0 border-light border rounded">
                            <p v-html="body_data"></p>
                        </blockquote>
                        <!-- <blockquote class="blockquote p-1 mb-0 border-light border rounded">
                            <p class="normal-text">
                                {{ body_data }}
                            </p>
                        </blockquote> -->
                        <!-- <div
                        v-if="!edit_mode"
                        style="text-align: center;"
                        class="draft-overlay d-flex align-items-center justify-content-center"
                        >
                        </div> -->
                    </div>
                    <div v-if="edit_mode" style="max-height: 200px" >
                        <Editor
                            @contentChanged="contentChanged"
                            :disable="!levelJob"
                            :content="body_data">
                        </Editor>
                    </div>
                    <span>
                        <span class="d-flex" style="justify-content: space-between;">
                            <span class="cursor-pointer ms-2 m-2 p-2 light_pink" @click="edit_mode = !edit_mode" style="color: #50A5F1; font-size: 14px; text-decoration: none !important;"> Edit <font-awesome-icon :icon="['fas', 'edit']" class="light_pink" /></span>
                            <span  class="ms-2 m-2 p-2">Author Name</span>
                        </span>
                    </span>
                    
                    
                </b-col>
                <b-col class="col-3 d-flex" style="flex-direction: row; justify-content: space-around;">
                    <div class="d-flex" style="flex-direction: column; justify-content: center;">
                        <div class="d-flex" style="flex-direction: column;">
                            <span class="purple_light" style="font-size: 13px; font-weight: bold">
                                Staff
                            </span>
                            <!-- <span class="mt-2" style="color: #506481" >
                                <span class="light_pink">Candidates</span> <span style="color: #6E84A7">(</span><span v-show="selectedJob.candidates_count">{{ selectedJob.candidates_count }}</span><span v-show="!selectedJob.candidates_count">0</span><span style="color: #6E84A7">)</span>
                            </span> -->
                            <span class="mt-2" style="color: #506481">
                                <span class="light_pink">Assigned People</span> <span style="color: #6E84A7">(</span><span v-show="selectedJob.members_count">{{ selectedJob.members_count }}</span><span v-show="!selectedJob.members_count">0</span><span style="color: #6E84A7">)</span>
                            </span>
                        </div>
                        <div class="d-flex" style="flex-direction: column;">
                            <span class="purple_light mt-2" style="font-size: 13px; font-weight: bold">
                                In Progress
                            </span>
                            <span class="mt-2" style="color: #506481">
                                <span class="light_pink">Onboarding</span> <span style="color: #6E84A7">(</span><span v-show="selectedJob.onboarding_count">{{ selectedJob.onboarding_count }}</span><span v-show="!selectedJob.onboarding_count">0</span><span style="color: #6E84A7">)</span>
                            </span>
                            <span class="mt-2" style="color: #506481">
                                <span class="light_pink">Offboarding</span> <span style="color: #6E84A7">(</span><span v-show="selectedJob.offboarding_count">{{ selectedJob.offboarding_count }}</span><span v-show="!selectedJob.offboarding_count">0</span><span style="color: #6E84A7">)</span>
                            </span>
                        </div>
                        
                    </div>
                    <div class="d-flex" style="flex-direction: column; justify-content: center;">
                        <div class="d-flex" style="flex-direction: column; ">
                            <span class="purple_light" style="font-size: 13px; font-weight: bold">
                            Skills
                            </span>
                            <span class="mt-2" style="color: #506481">
                                <span class="light_pink">Total</span> <span style="color: #6E84A7">(</span><span v-if="selectedJob.skills_count">{{ selectedJob.skills_count }}</span><span v-show="!selectedJob.skills_count">0</span><span style="color: #6E84A7">)</span>
                            </span>
                            <span class="mt-2" style="color: #506481">
                                
                            </span>
                        </div>
                        <div class="d-flex" style="flex-direction: column;">
                            <span class="purple_light mt-2" style="font-size: 13px; font-weight: bold;">
                            Skill Types
                            </span>
                            <span class="mt-2" style="color: #506481">
                                <span class="light_pink">Core</span> <span style="color: #6E84A7">(</span>{{ getCount(2) }}<span style="color: #6E84A7">)</span>
                            </span>
                            <span class="mt-2" style="color: #506481">
                                <span class="light_pink">Manage</span> <span style="color: #6E84A7">(</span>{{ getCount(1) }}<span style="color: #6E84A7">)</span>
                            </span>
                            <span class="mt-2" style="color: #506481">
                                <span class="light_pink">Nice to have</span> <span style="color: #6E84A7">(</span>{{ getCount(3) }}<span style="color: #6E84A7">)</span>
                            </span>
                            <span class="mt-2" style="color: #506481">
                                <span class="light_pink">Extra</span> <span style="color: #6E84A7">(</span>{{ getCount(4) }}<span style="color: #6E84A7">)</span>
                            </span>
                        </div>
                    </div>
                    
                    
                </b-col>
                <b-col class="col-4 d-flex" style="flex-direction: column; position: relative !important;" v-if="jobDetailsEditMode">
                    <!-- <span class="btn-group m-2 p-1">
                        <span class="w-100 purple_light">
                            <span>Onboarding Process</span>
                            <b-form-select class="user_select" v-model="selected_role" :options="onBoardingProcess"></b-form-select>
                        </span>
                    </span> -->
                        <span class="btn-group m-2 p-1">
                            <span class="purple_light" style="width: 90%">
                                <span>Job Grade</span>
                                <b-form-select :class="levelJob ? 'job_select' : 'disabled_job_select'" :disabled="!levelJob" v-model="selectedJobGrade" @change="saveJobGrade" :options="jobGrade"></b-form-select>
                            </span>
                        </span>
                        <span class="btn-group m-2 p-1">
                            <span class="purple_light" style="width: 90%;">
                                <span>Scorecard title/template</span>
                                <b-form-select :class="levelJob ? 'job_select' : 'disabled_job_select'" :disabled="!levelJob" v-model="selectedScorecard" @change="saveJobTemplate" :options="scoreCardTemplate"></b-form-select>
                            </span>
                        </span>
                </b-col>
                <b-col class="col-4 d-flex" style="flex-direction: column;" v-if="!jobDetailsEditMode">
                    <!-- <span class="btn-group d-flex m-2 p-1 purple_light" style="flex-direction: column;">
                        <span>
                            Onboarding Process
                        </span>
                        <span>
                            value
                        </span>
                    </span> -->
                    <span class="btn-group d-flex m-2 p-1 pt-2 purple_light" style="flex-direction: column;">
                        <span>
                            Job Grade
                        </span>
                        <span>
                            {{getJobGrade}}
                        </span>
                    </span>
                    <span class="btn-group d-flex m-2 p-1 pt-2 purple_light" style="flex-direction: column;">
                        <span>
                            Scorecard title/template
                        </span>
                        <span>
                            {{getTemplateName}}
                        </span>
                    </span>
                </b-col>
            </span>
            <div class="user_footer d-flex col-12 mt-1" style="background-color: #34C38F; height: 52px !important; font-size: 1.2rem; justify-content: end; align-items: center;">
            <div class="m-2 me-4">
                <span>
                Assigned People
                </span>
                <span class="ms-2" style="font-size: 1.8rem !important">
                    <span v-show="selectedJob.members_count != null">{{ selectedJob.members_count }}</span>
                    <span v-show="!selectedJob.members_count">0</span>
                </span>
            </div>
        </div>
        </div>
        <!-- {{ selectedJob }} -->
        <!-- <jobDetailsDescriptionModal v-if="show_description_modal" /> -->
    </div>
</template>
<script> 
import { peopleComputed, peopleMethods, profileComputed, profileMethods, levelComputed, levelMethods, scorecardComputed, scorecardMethods } from '@/state/helpers'
import Editor from "@/components/widgets/simpleEditorWrapper.vue"
//eslint-disable-next-line
export default {
    name: "jobDetailsHeader",
    components:{
        Editor
    },
    props: {
        mode: {
            default: false
        }
    },
    watch: {
        jobDetailsEditMode() {
            if(this.jobDetailsEditMode) {
                this.edit_mode = true
            } else {
                this.edit_mode = false
            }
        },
        data_changed() {
            if(this.data_changed) {
                this.autoSaveTimer()
            } else {
                this.cleanupAutoSave()
            }
        },
        edit_mode() {
            if(!this.edit_mode) {
                this.body_data = this.updated_body_text
            }
        }
        // selectedScorecard() {
        //     if(this.selectedScorecard) {
        //         this.saveJobTemplate(this.selectedScorecard)
        //     }

        // }
    },
    data: () => ({
         edit_mode: false,
         body_data: "",
         userData: [{id:1, name: "Andrew", avatar: '/img/user.png', start: '17/04/2024', end: '17/04/2024', roles: [{ division: 'Executive Level', manager: 'Nauman', start: '17/04/2024', end: ' - ', score: '9'}] }],
         selected_user: 1,
         selected_role: 1,
         selectedScorecard: null,
         selectedJobGrade: null,
         updated_body_text: "",
         saving: false,
         data_changed: false,
         onBoardingProcess: [{value: 1, text: 'No Process selected'}],
         offBoardingProcess: [{value: 1, text: 'No Process selected'}],
         jobGrade: [{value: null, text: 'No Job Grade'},{value: 1, text: 'Junior'},{value: 2, text: 'Middle'},{value: 3, text: 'Manager'},{value: 4, text: 'Senior Manager'}],
         scoreCardTemplate: [{value: null, text: 'No Template Selected'}],
        //  roles: [{value: 1, text: 'Test'}],
         name: '',
         time: 0, // Initialize time
         timeoutID: null, // Store timeout ID for managing the timer
    }),
    computed:{
        ...peopleComputed,
        ...profileComputed,
        ...levelComputed,
        ...scorecardComputed,
        selectedJob(){
            if(this.companyJobs != null) {
                let data = this.companyJobs
                let value = []
                data.forEach(job => {
                    if(job.id == this.activeJobId) {
                        value = job
                    }
                })
                return value
            } else {
                return null
            }
        },
        getJobGrade() {
            if(this.levelJob) {
                //if(this.levelJob.scorecard_templates.length > 0) {
                    return this.levelJob.jobGrade
                //}
            }
            return 'No Job Grade Selected'
        }   ,
        getTemplateName() {
            if(this.levelJob) {
                if(this.levelJob.scorecard_templates.length > 0) {
                    return this.levelJob.scorecard_templates[0].name
                }
            }
            return 'No Template Selected'
        }      
    },
    methods:{
        ...peopleMethods,
        ...profileMethods,
        ...levelMethods,
        ...scorecardMethods,
        saveJobGrade() {
                let item_to_save = [{
                    id: -1,
                    title: this.levelJob.title,
                    level_id: this.levelJob.level_id,
                    grade: this.selectedJobGrade
                }];
                this.saveHiringJob(item_to_save)
        },
        saveJob(){
            if(this.name == '') {
                return
            }
            if(this.levelJob == null) {
                let item_to_save = [{
                    id: -1,
                    title: this.name,
                    level_id: this.company_id,
                }];
                this.saveHiringJob(item_to_save)
                .then(()=>{
                    this.loadCompanyJobs(this.company_id)
                    .then(()=>{
                        let job = this.companyJobs.find( job => {
                          return job.title == this.name
                        });

                        this.getLevelJob(job.id)
                    })

                })
            } else {
                if(this.levelJob.name != this.name) {
                    let items_to_save = [{
                    id: this.levelJob.id,
                    title: this.name,
                    level_id: this.company_id,
                    }];
                    this.saveHiringJob(items_to_save)
                    .then(()=>{
                        this.name = this.levelJob.title
                    })
                }

            }
           
            
        },
        contentChanged(new_content) {
            console.log("NEW CONTENT")
            let span = document.createElement('span');
            span.innerHTML = new_content;
            this.updated_body_text = span.textContent;
            console.log(this.updated_body_text)
            if(this.updated_body_text != this.body_data) {
                this.data_changed = true
                this.resetTimer();
            }
            
            // if(this.updated_body_text != this.body_data) {
            //     console.log('getting in here')
                
            // }
            
        },
        setFilter(value) {
            this.selected_filter = value
        },
        setYearFilter(value) {
            this.selected_year_filter = value
        },
        getCount(value) {
            if(this.selectedJob.skills) {
                let count = 0
               this.selectedJob.skills.forEach(skill => {
                   if(skill.category_id == value) {
                      count++
                   }
               })
               return count
            } else {
                return 0
            }
        },
        showDescriptionModal() {
            //this.modal_payload = payload;
            //console.log(JSON.parse(JSON.stringify(this.modal_payload)));
            this.show_description_modal = true;
            this.$nextTick(() => {
                this.$bvModal.show('jobDescriptionModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "jobDescriptionModal") {
                        this.show_description_modal = false;
                    }
                });
            })
        },
        autoSaveTimer() {     
            this.startTimer();
        },
        cleanupAutoSave() {
            // Clear the timer
            clearTimeout(this.timeoutID);
        },
        resetTimer() {
            clearTimeout(this.timeoutID);
            this.time = 0;
            this.startTimer();
        },
        startTimer() {
            const timer = () => {
                console.log(this.time)
                if (this.time > 1) {
                    this.triggerAutoSave()
                }
                else {
                    this.time++;
                    this.timeoutID = setTimeout(timer, 1000);
                }
            };
            timer();
        },
        triggerAutoSave() {
            console.log("SAVING here")
            console.log(this.updated_body_text)
            this.saving = true
            var params = [{id: this.levelJob.id, level_id: this.levelJob.level_id, description: this.updated_body_text, title: this.levelJob.title }];
            this.saveHiringJob(params).then((response) => {
                setTimeout(()=>{
                    //this.was_data_changed = false
                    
                    if (response.status === 200 || response.status === 304) {
                        this.data_changed = false
                        console.log(response.data.saved_data[0].description)
                        //this.body_data = response.data.saved_data[0].description
                        this.updated_body_text = response.data.saved_data[0].description
                        this.saving = false
                        //this.resetTimer()
                        this.cleanupAutoSave();
                    } 
                }, 500);
            })
        }, 
        saveJobTemplate() {
            let params1 = {job_id :this.levelJob.id, templates:[]}
            let params = {
                job_id: this.levelJob.id,
                template_id: null,
            }
            if(this.selectedScorecard) {
                params.template_id = this.selectedScorecard
                params1.templates = [this.selectedScorecard]
            }
            this.saveDefaultJobScorecardTemplate(params).then(() => {
                this.saveJobScorecardTemplates(params1).then(() => {
                    this.getLevelJob(this.levelJob.id)
                })   
            });
            
        }, 
        setTemplate() {
            if(this.levelJob) {
                if(this.levelJob.scorecard_templates.length > 0) {
                    this.selectedScorecard = this.levelJob.scorecard_templates[0].id
                }
            }
        } 
    },
    mounted(){
        this.setTemplate()
        if(this.levelJob) {
            this.body_data = this.levelJob.description
            this.name = this.levelJob.title
        } 
        //this.setLoading(false);
        if(this.jobDetailsEditMode) {
            setTimeout(() => {
                this.edit_mode = true
                document.getElementById('jobNameInput').focus()
            }, 800)       
        }
        this.loadScorecardTemplates({
            show_archived: 0,
        }).then(() => {
            let templates = this.scorecard_templates
            let modified_templates = []
            
            if(templates.length > 0) {
                templates.forEach(template => {
                    let item = {}
                    item.text = template.name
                    item.value = template.id
                    modified_templates.push(item)
                })
            }
            let data = this.scoreCardTemplate
            this.scoreCardTemplate = data.concat(modified_templates);
            console.log(JSON.parse(JSON.stringify(this.scoreCardTemplate)))
        })
    },
}

</script>

<style>
.job_header {
   color: white;
   border-top-left-radius: 18px;
   border-top-right-radius: 18px;
}
.user_footer {
   color: white;
   border-bottom-left-radius: 18px;
   border-bottom-right-radius: 18px;
}
.imgStyles {
   height: 40px;
   width: 40px;
   margin: 3px
}
.job_container { 
    /* height: 320px;  */
    background-color: #FFFFFF !important;
    border-radius: 20px;
}
.job_select {
    text-align: center; 
    border: 1px solid #50A5F1;  
    border-radius: 15px; color: #50A5F1; 
    font-size: 0.7rem !important;
    height: 1.6rem;
    width: 100%
}
.disabled_job_select {
    text-align: center; 
    border: 1px solid grey;  
    border-radius: 15px; color: grey; 
    font-size: 0.7rem !important;
    height: 1.6rem;
    width: 100%
}
.simple-editor div .ql-container{
    min-height: 160px !important;
}
#jobNameInput:focus {
    border-color: #50A5F1;
    /* box-shadow: 0 0 0 0.2rem #50A5F1; */
}
</style>
