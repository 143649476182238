<template>
    <div>
        <JobsTable @detailsView="showDetails" :company_level_id="company_level_id"/>

        <!-- <b-sidebar id="job-sidebar" ref="sidebar3"  header-class="custom-scorecard-class" sidebar-class="jobs-sidebar" lazy width="100%" style="overflow: auto;" right  backdrop no-header shadow="lg">
        </b-sidebar> -->
        <DetailsViewContainer v-if="job_details_view" >
            <JobDetailsPage @exit="job_details_view = false;"  />
        </DetailsViewContainer>
    </div>
</template>

<script>
import JobDetailsPage from './components/jobs/components/jobDetailsPage.vue';
import JobsTable from './components/jobs/jobsTable.vue'
import DetailsViewContainer from "@/components/widgets/containers/detailsOverlayContainer.vue";
//import SkillsTable from './components/jobs/skillsTable.vue'
//import OnboardingTable from './components/jobs/onboardingTable.vue'
//import OffboardingTable from './components/jobs/offboardingTable.vue'
import { peopleComputed, peopleMethods, levelComputed, profileComputed, profileMethods } from '@/state/helpers'
export default {
    components:{
        JobsTable,
        JobDetailsPage,
        DetailsViewContainer
        //SkillsTable,
        //OnboardingTable,
        //OffboardingTable,
    },
    data:() => ({
        company_level_id: null,
        is_loading: false,
        job_details_view: false
    }),
    watch:{
        // company_level_id:{
        //     immediate: true,
        //     handler(new_id){
        //         if(new_id != null){
        //             this.loadCompanyJobs(this.company_level_id);
        //         }
        //     }
        // },
        current_level_id(){
            // this.setCompanyLevelId();
            this.company_level_id = this.company_id
        },
        selected_level() {
            this.company_level_id = this.company_id
        },
    },
    computed:{
        ...peopleComputed,
        ...levelComputed,
        ...profileComputed,
        hasJobSelected(){
            //* only show the skills and off/onboarding tables if there is a job selected
            if(this.selectedJobId != -1){
                return true;
            }
            else{
                return false;
            }
        }
    },
    methods:{
        ...peopleMethods,
        ...profileMethods,
        showDetails() {
            console.log('showing details')
            this.job_details_view = true
            // this.$root.$emit('bv::toggle', 'sidebar-3')
            // this.$refs.sidebar3.toggle();
            //this.$root.$emit('bv::toggle::collapse', 'job-sidebar')
        }
    },
    beforeDestroy(){
        //* set selected job to null before switching tabs
        this.loadCompanyJobs(this.company_id);
        this.setActiveJobId(-1);
        this.setSelectedJobId(-1);
    },
    mounted(){
        if(this.selected_level != null){
            //this.setCompanyLevelId(); 
            this.company_level_id = this.company_id  
        }
    }
}
</script>

<style>
.jobs-sidebar{
    border-right: 1px solid grey;
    width: 100%

}

.b-sidebar-outer > .b-sidebar-backdrop{
    background-color: rgb(20 88 155) !important;
}

.jobs-sidebar .b-sidebar-body {
    display: flex;
    justify-content: center;
}
</style>