<template>
    <b-row class="mt-2">
        <b-col cols="12">
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :ref="table_uid"
                    :data="risk_data"
                    :default_values="default_values" 
                    :context_menu_items="['View', 'Edit', 'Delete']" 
                    :active_id="active_id"
                    :pager_config="10" 
                    :table_mode="table_view_mode"
                    :button_methods="button_methods"
                    :validation="table_validation_rules" 
                    @saveTable="saveTable"
                    @selectedRowEvent="selectedRowEvent" 
                    @deleteRows="deleteRow"
                    hide_action_buttons
                    no_extra_rows 
                    @updateViewMode="handleTableModeUpdate"
                    :edit_permissions="{manager:'friction-risks'}"
                    :required_permissions="['friction-risks']"
                    guideline_key="friction_risks_table"
                >
                    <template #title>
                        Risk Register
                    </template>
                    <template #buttons>  
                    <b-button size="sm" variant="primary" @click="riskModal({})" class="me-1" style="display: inline-block">
                        <font-awesome-icon :icon="['fas', 'fa-plus']" />
                    </b-button>
                </template>
                </Table>
        </b-col>
        <riskModal :payload="modal_payload" v-if="show_risk_modal" @saveTable="saveTable" />
        <ObjectiveModal v-if="show_objective_modal" :not_objective="true" :objective_type="'frictionAndRisks'" :payload="modal_payload" @selectedNewID="handleObjectiveUpdateEvent" @updateNewID="handleObjectiveUpdateEvent"/>
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { levelComputed, peopleComputed, profileComputed, activityComputed, activityMethods, performanceComputed, performanceMethods, profileMethods } from '@/state/helpers'
import _ from 'lodash';
import ObjectiveModal from '../../objectives/components/misc/objectiveModals/linkedObjectiveModal.vue'
import { format, parseISO } from 'date-fns';
import riskModal from './profileModals/riskModal.vue';
export default {
    name: 'riskRegister',
    components: {
        Table,
        ObjectiveModal,
        riskModal
    },
    props:{
        initial_table_mode:{
            default: 'view',
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'riskRegister',
        table_view_mode: 'view',
        is_loading: false,
        show_objective_modal: false,

        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        new_view_mode: 'view',
        objective_type: 'level',
        show_risk_modal: false,
        modal_payload: {}
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        ...activityComputed,
        ...performanceComputed,
        required_values() {
            let values = ['1','2','3','4','5','6','7','8','9','10']
            return values
        },
        userList(){
            let users = [];
            _.forEach(this.all_users, function(value) {
                users.push({id: value.id, value: value.name, email: value.email});
            });
            return users;
        },
        risk_data(){
            if(this.teams.risks == undefined){
                return [];
            }
            else{
                return _.uniqBy(this.teams.risks, 'id');
            }
        },
        //* table computed methods
        columns(){
            //* get ref of current scope
            let el = this
            ///// ADD column to 
            let risk_columns = [
                { id: 'name', header: 'Risk', fillspace: 3, editor: "text",},
                // { id: 'impact', header: 'Impact', fillspace: 2, editor: 'text' },
                { id: 'impact', header: 'Impact', fillspace: 1, editor: "combo", options: el.required_values, css: 'centerValues',
                    suggest:{
                        view:"suggest",
                        body:{
                            view:"list",
                            data: el.required_values,
                            template:"#id#",
                            yCount:10
                        }
                    },
                },
                { id: 'likelyhood', header: 'Likelyhood', fillspace: 1, editor: "combo", options: el.required_values, css: 'centerValues',
                    suggest:{
                        view:"suggest",
                        body:{
                            view:"list",
                            data: el.required_values,
                            template:"#id#",
                            yCount:10
                        }
                    },
                },
                { id: 'date', header: 'Active Date', fillspace: 1, editor: 'date',
                    //* example on how to format date without mutating the origional data
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
                {
                    id: "milestone_objective_id",
                    header: "Linked Objective",
                    width: 220,
                    template:function(obj){
                        if(obj.is_empty){
                            return '';
                        }
                            let name = 'Not Linked'; 
                            let found_objective = el.getAllObjectives.find( (item) => {
                                return item.id == obj.milestone_objective_id
                            })
                            
                            if(found_objective != undefined){
                                // return `<div class="show_objective_modal show_hierarchy_text">
                                //     <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="pen" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class=" svg-inline--fa fa-pen"><path fill="currentColor" d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" class=""></path></svg>
                                //         <span class="ms-1">Awe</span>
                                // </div>`
                                //return `<div class="show_objective_modal show_hierarchy_text">Awe</div>`
                                console.log(JSON.parse(JSON.stringify(found_objective)))
                                name = found_objective.name;
                            }
                            return `<span>
                                    ${name} 
                                </span>`
                    }
                },
                {
                id: "user_id",
                editor: "combo",
                header: "Owner",
                minWidth: 250,
                options: this.userList,
                suggest:{
                    view:"suggest",
                    filter:function(item, value){
                        let text = value.toLowerCase();
                        let name = item.value.toLowerCase();
                        let email = item.email.toLowerCase();
                        if(name.includes(text) || email.includes(text)){
                            return true;
                        }
                        else{
                            return false;
                        }
                    },
                    body:{
                        view:"list",
                        data:this.userList,
                        template:"#value# - #email#",
                        yCount:10
                    }
                },
                }
            ];
            return risk_columns;
        },
        table_validation_rules() {
            //eslint-disable-next-line
            let el = this;
            return {
                "name": function (item, obj) {
                    if (obj.is_empty) {
                        return true;
                    }
                    else {
                        if (item === '' || item == undefined) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                },
                "impact": function (item, obj) {
                    if (obj.is_empty) {
                        return true;
                    }
                    else {
                        if (item === '' || item == undefined) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                },
                "likelyhood": function (item, obj) {
                    if (obj.is_empty) {
                        return true;
                    }
                    else {
                        if (item === '' || item == undefined) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                },
                "date": function (item, obj) {
                    if (obj.is_empty) {
                        return true;
                    }
                    else {
                        if (item === '' || item == undefined) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                },
                "user_id": function (item, obj) {
                    if (obj.is_empty) {
                        return true;
                    }
                    else {
                        if (item === '' || item == undefined) {
                            return false;
                        }
                        else {
                            return true;
                        }
                    }
                },
            };
        },
        // getAvailableObjectives(){
        //     let names = [
        //         {id: 'null', value: "Not Linked"}
        //     ];

        //     let value = this.current_objective_details
            
        //     if(value != undefined){
        //         if(value.objectives != null) {
        //             value.objectives.forEach( item =>{
        //                 names.push(item);
        //             })
        //         }   
        //     }          
        //     return names;
        // },
        getAllObjectives(){
            if(this.teams.linkable_objectives.length > 0) {
                return this.teams.linkable_objectives
            } else {
                return []
            }
        },
        default_values(){
            let el = this;
            return [    
                { id: "milestone_objective_id", value: el.selected_goal_objective != -1 ? el.selected_goal_objective : -1}, 
            ]
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'show_objective_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showObjectiveModal(item);
                },
                'edit_action':function(e, id){
                    let item = this.getItem(id)
                    el.riskModal(item)
                },
            }
            return onClick;
        },
    },
    methods:{
        ...activityMethods,
        ...performanceMethods,
        ...profileMethods,
        //* table event handlers
        handleTableModeUpdate(new_mode){ 
            this.new_view_mode = new_mode
        },
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            this.active_id = row ? row.id : -1;
        },
        showObjectiveModal(payload){
            /*if(this.changes_made_flag){
                Swal.fire('Please save new rows before making hierarchy changes')
            }*/
            //else{
                if(this.new_view_mode === 'edit'){
                    this.modal_payload = _.cloneDeep(payload);
                    this.modal_payload['modal_type'] = 'frictionAndRisks'
                    let options = this.teams.linkable_objectives;
                    let index = _.findIndex(options, {id: Number(payload.milestone_objective_id)});
                    if(index != -1){
                        this.modal_payload['name'] = options[index].name
                    } else {
                        this.modal_payload['name'] = 'No objective selected'
                    }
                    this.modal_payload['parent_objective_id'] = Number(payload.milestone_objective_id)
                    this.show_objective_modal = true;
                    this.$nextTick(()=>{
                        this.$bvModal.show('linkedObjectiveModal');
                        this.$root.$once("bv::modal::hidden", (event) => {
                            if (event.type == "hidden" && event.componentId == "linkedObjectiveModal") {
                                this.show_objective_modal = false;
                                this.modal_payload = null;
                            }
                        });
                    })
                }
            //}
        },
        handleObjectiveUpdateEvent(newID){
            console.log(newID)
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.id);
            item['milestone_objective_id'] = newID;

            console.log(item);
            console.log(this.modal_payload.id)
            table.updateItem(this.modal_payload.id, item);
            this.$bvModal.hide('linkedObjectiveModal');
        },
        saveTable(data){
            let table = window.webix.$$('riskRegister');
            // eslint-disable-next-line
            this.$nextTick(()=>{
                let is_valid = true;  
                data.forEach( item => {
                    let result = table.validate(item.id);
                    if(result != true){
                        is_valid = false;
                    }
                });
                if(is_valid){
                    this.is_loading = true;
                    
                    let rows_to_save = [];
                    data.forEach( row => {
                        if(!row.is_empty){
                            let newRow = row.is_new;
                            var params = {
                                id: newRow ? 0 : row.id,
                                level_id: this.selected_level.id,
                                objective_id: (row.milestone_objective_id && row.milestone_objective_id != -1) ? Number(row.milestone_objective_id) : '',
                                name: row.name,
                                date: typeof row.date === 'string' ? format(parseISO(row.date), 'yyyy-MM-dd') : format(row.date, 'yyyy-MM-dd'),
                                likelyhood: row.likelyhood,
                                impact: row.impact,
                            };
                            rows_to_save.push(params);
                        }
                    })

                    console.log(rows_to_save)

                    let params = {
                        risks: rows_to_save,
                    }

                    this.saveObjectiveRisk(params)
                    .then(()=>{
                        this.loadTeams(this.selected_level.id)
                        .then(() => {
                            this.$refs[this.table_uid].table_mode = 'view';
                            this.is_loading = false;
                        })
                    });

                }
            })
        },
        deleteRow(data) {
            let promises = [];
            data.forEach(item => {
                promises.push(this.deleteObjectiveRisk(item));
            })
            this.is_loading = true;
            Promise.all(promises)
                .then(() => {
                    this.loadTeams(this.selected_level.id)
                        .then(() => {
                            this.$refs[this.table_uid].table_mode = 'view';
                            this.is_loading = false;
                        })
                })
                .catch(() => {
                    this.is_loading = false;
                })
        },
        riskModal(item){
            this.modal_payload = _.cloneDeep(item);
            this.show_risk_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('riskModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "riskModal") {
                        this.show_risk_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
    },
    mounted(){
        //this.loadActivityData(this.selected_level.id)
        this.loadPerformanceScreenData({
            type: 'level',
            params: {
                level_id: this.current_level_id, milestone_data: 1
                },
        })
    },
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
</style>