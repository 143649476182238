<template>
    <b-row>
        <b-col cols="12">
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :id="table_uid"
                    :ref="table_uid"
                    :data="data"
                    :is_loading="is_loading"
                    only_editable_columns
                    column_autoheight_key="name"
                    hide_action_buttons
                    disable_delete
                    no_extra_rows
                    :active_id="active_id"
                    prevent_first_row_select
                    :table_mode="table_view_mode"
                    @saveTable="saveTable"
                    @deleteRows="deleteRow"            
                >
                    <template #title>
                        Assigned People
                    </template>
                </Table>
        </b-col>
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { format, parseISO } from "date-fns";
import _ from 'lodash'
import { levelComputed ,peopleComputed, profileComputed, profileMethods } from '@/state/helpers'
import dateMixin from '@/mixins/dateMixin.js'
import Swal from 'sweetalert2'
export default {
    mixins:[ dateMixin ],
    name: 'assignedPeople',
    components: {
        Table,
    },
    props:{
        initial_table_mode:{
            default: 'edit',
        }
    },
    watch: {
        // jobDetailsEditMode() {
        //     if(this.jobDetailsEditMode) {
        //         this.$refs[this.table_uid].table_mode = 'edit';
        //     } else {
        //         this.$refs[this.table_uid].table_mode = 'view';
        //     }
        // }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'assignedPeople',
        table_view_mode: 'edit',
        loading: true,
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        disableEdit: false
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        //* table computed methods
        columns(){
            let el = this;
            let columns = [
                { id: 'name', header: 'Name', editor: 'text', fillspace: 1,
                    template: function(obj) {
                        console.log(obj)
                        if(obj.is_empty || obj.is_new){
                           return '';
                        }
                        if(!obj.name) {
                           return ''
                        }
                        return "<img class='rounded-circle header-profile-user' src='"+el.getImgSrc(obj)+"' style='width:35px; margin: 5px'>" + obj.name
                    }
                },
                { id: "employment_start_date", header: "Employment Start Date", editor: "date", sort: "date", width: 150, css: 'centerValues',
                    format:function(value){
                        //TODO   node/parentNode/childNodes/innerText
                        let date = _.cloneDeep(value);
                        if(date !== ''){
                        if(typeof date === 'string'){
                            date = parseISO(date);
                        }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                { id: "role_start_date", header: "Role Start Date", editor: "date", sort: "date", width: 150, css: 'centerValues',
                    format:function(value){
                        //TODO   node/parentNode/childNodes/innerText
                        let date = _.cloneDeep(value);
                        if(date !== ''){
                        if(typeof date === 'string'){
                            date = parseISO(date);
                        }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                { id: "end_date", header: "End", editor: "date", sort: "date", width: 150, css: 'centerValues',
                    format:function(value){
                        //TODO   node/parentNode/childNodes/innerText
                        let date = _.cloneDeep(value);
                        if(date !== ''){
                        if(typeof date === 'string'){
                            date = parseISO(date);
                        }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '-';
                        }
                    },
                },
                { id: 'duration', header: 'Time in role', width: 150, editor: "text" },
                { id: 'onboarding', header: 'Onboarding', width: 150, editor: "text", css: 'centerValues',
                   template: function(obj) {
                    console.log(obj)
                    if(obj.is_empty || obj.is_new){
                        return '';
                    }
                    return "<i class='fas fa-2x fa-times-circle mt-1'></i>"
                    // return <i class="fas fa-check-circle"></i>
                }},
                { id: 'offboarding', header: 'Offboarding', width: 150, editor: "text", css: 'centerValues',
                   template: function(obj) {
                    console.log(obj)
                    if(obj.is_empty || obj.is_new){
                        return '';
                    }
                    return "<i class='fas fa-2x fa-times-circle mt-1'></i>"
                    // return <i class="fas fa-check-circle"></i>
                }},
                { id: 'scorecardRating', header: 'Scorecard Rating', width: 150, editor: "text" }
            ];
            return columns;
        },
        data(){
            if(!this.levelJob){
                return [];
            }
            else{
                return this.levelJob.employees;
            }
        },
    },
    methods:{
        ...profileMethods,
        getImgSrc(data) {
            let user = _.find( this.levelEmployeesData, { id: data.id }) 
            if(user) {
                return user.avatar_src
            }
            return ''
        },
        //* table event handlers
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            if(row !== null){
                this.active_id = row.id;
            } else {
                this.active_id = -1
            }
        },
        showDiscardPrompt(){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    // this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        saveTable(data){
            this.is_loading = true;
            let rows_to_save = [];
            data.forEach( row => {
                
                if(!row.is_empty){
                    let newRow = row.is_new;
                    var params = {
                        id: newRow ? -1 : row.id,
                        level_id: this.selected_level.id,
                        value: row.value,
                        how: row.how,
                    };
                    rows_to_save.push(params);
                }
            })

            let params = {
                level_values: rows_to_save,
            }
            this.is_loading = true;
            this.saveValues(params).then(()=>{
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view'
                    })
                })
        },
        deleteRow(data) {
            let promises = [];
            this.is_loading = true;
            data.forEach( item => {
                promises.push(this.deleteValues({id: item.id}));
            })
            Promise.all(promises)
            .then(()=>{
                this.loadTeams(this.selected_level.id)
                .then(()=>{
                    this.$refs[this.table_uid].table_mode = 'view';
                    this.is_loading = false;
                })
			})
            .catch(()=>{
                this.is_loading = false;
            })
        },
    },
    beforeMount(){
        this.table_view_mode = this.initial_table_mode;
       
    },
    mounted(){
        this.$nextTick(() => {
            if(this.jobDetailsEditMode) {
                this.$refs[this.table_uid].table_mode = 'edit';
            } 
        })
    },
}
</script>

<style>

</style>