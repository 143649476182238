<template>
    <b-modal id="frictionModal" centered title="Add Friction" size="lg" hide-footer>
        <b-form @submit.prevent>
            <div class="container">
                <div class="row">
                    <div class="col-sm p-0">
                        <b-form-group class="label" id="input-group-1" label="Friction Name:" label-for="input-1" size="sm">
                            <b-form-input
                            id="input-1"
                            size="sm"
                            v-model="params.name"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-sm p-0" style="margin-left: 4px;">
                        <b-form-group class="label" id="input-group-1" label="Type:" label-for="input-1" size="sm">
                            <b-form-input
                            id="input-1"
                            size="sm"
                            v-model="params.friction_type"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
            </div>
           
            <div class="container">
                <div class="row">
                    <div class="col-sm p-0">
                        <b-form-group id="input-group-3" label="Organisation Structure:" label-for="input-3" size="sm" >
                        <b-row>
                            <span @mouseover="onOverItem('levelsDropdown', true)" @mouseleave="onOverItem('levelsDropdown', false)" >
                                <b-dropdown id="dropdownTree" ref="levelsDropdown" no-caret :text="getLevelForID" variant="ouline" size="sm" class="dropdownStyles dropdown-toggle-no-carret">
                                    <span class="dropdown-item dropdownItemStyles dropdown-toggle-no-carret" no-caret @click.stop="" href="#"><HierarchyTree class="dropdown-toggle-no-carret" v-if="show_levels_tree" no-caret @selectedNewID="handleLevelUpdateEvent" :payload="levels_tree_payload" show_all_levels /></span>
                                </b-dropdown>
                            </span>
                        </b-row>
                    </b-form-group>
                    </div>
                    <div class="col-sm p-0" style="margin-left: 4px;">
                        <b-form-group id="input-group-3" label="Select Objective:" label-for="input-3" size="sm" >
                        <b-row>
                            <!-- <b-col cols="8" style="margin-right: 5px; padding-right: 0px; height:100%; width: 85%">
                                <b-form-input
                                    id="input-3"
                                    size="sm"
                                    v-model="getObjectiveFromId"
                                    placeholder="Select Objective"
                                    required
                                    readonly
                                ></b-form-input>
                            </b-col>
                            <b-col cols="4" style="display:flex; justify-content: center; padding: 0; margin: 0; width: auto">
                                <b-button variant="primary" size="sm" @click="showObjectiveModal(params)">
                                    <i class="fas fa-pen"></i>
                                </b-button>
                            </b-col>  -->
                            <span @mouseover="onOverItem('objectivesDropdown', true)" @mouseleave="onOverItem('objectivesDropdown', false)">
                                <b-dropdown id="dropdownTree2" ref="objectivesDropdown" no-caret :text="getObjectiveFromId" variant="ouline" size="sm" class="dropdownStyles dropdown-toggle-no-carret">
                                    <span class="dropdown-item dropdownItemStyles dropdown-toggle-no-carret" no-caret @click.stop="" href="#"><ObjectiveTree v-if="show_objectives_tree" :not_objective="true" :objective_type="'frictionAndRisks'" :payload="objectives_tree_payload" @selectedNewID="handleObjectiveUpdateEvent" @updateNewID="handleObjectiveUpdateEvent"/></span>
                                </b-dropdown>
                            </span>
                        </b-row>
                    </b-form-group>
                    </div>
                </div>
            </div>
            <div class="text-end pt-2 mt-3">
                <b-button variant="light" @click="$bvModal.hide('frictionModal')">Close</b-button>
                <b-button @click="save()" variant="success" class="ms-1">Save</b-button>
            </div>
        </b-form>
        <HierarchyModal @selectedNewID="handleLevelUpdateEvent" :payload="modal_payload" show_all_levels v-if="show_level_linking_modal" />
        <ObjectiveModal v-if="show_objective_modal" :not_objective="true" :objective_type="'frictionAndRisks'" :payload="modal_payload" @selectedNewID="handleObjectiveUpdateEvent" @updateNewID="handleObjectiveUpdateEvent"/>
    </b-modal>
</template>

<script>
import { levelComputed, peopleMethods, peopleComputed, profileComputed } from "@/state/helpers";
import HierarchyModal from '@/components/objectives/components/misc/hierarchyModal.vue'
import ObjectiveModal from '../../../objectives/components/misc/objectiveModals/linkedObjectiveModal.vue'
import ObjectiveTree from '../../../objectives/components/misc/dropdownComponents/objectivesTree.vue'
import HierarchyTree from "../../../objectives/components/misc/dropdownComponents/hierarchyTree.vue";
import _ from 'lodash'
// /import _ from 'lodash'
export default {
    components:{
        HierarchyModal,
        ObjectiveModal,
        ObjectiveTree,
        HierarchyTree
    },
    props:{
        payload:{
            require: false
        },
    },
    data:() => ({
        params:{
            id: 0,
            name:'',
            level_id: null,
            objective_id: null,
            type: '',
            modal_type: 'objective'
        },
        modal_payload: {},
        show_level_linking_modal: false,
        show_objective_modal: false,
        show_objectives_tree: false,
        show_levels_tree: false,
        levels_tree_payload: {},
        objectives_tree_payload: {}
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        getObjectiveFromId() {
            let name = 'Not Linked';
            let value = this.teams.linkable_objectives.find( (item) => {
                return item.id == this.params.objective_id
            })
            if(value) {
                return value.name
            }
            return name
        },
        getLevelForID(){
            let level = this.levels.find( level => {
                return level.id == this.params.level_id
            });
            if(level) {
                return level.name
            } 
            return 'No Level Linked'
        },
    },
    methods:{
        ...peopleMethods,
        onOverItem(item, value) {
            switch (item) {
            case "levelsDropdown":
                this.show_levels_tree = value
                this.$refs.levelsDropdown.visible = value;
              break;
            case "objectivesDropdown":
                this.show_objectives_tree = value
                this.$refs.objectivesDropdown.visible = value;
              break;
          }
        },
        handleObjectiveUpdateEvent(data) {
            this.params.objective_id = data
            this.objectives_tree_payload = _.cloneDeep(this.objectives_tree_payload);
            this.objectives_tree_payload['modal_type'] = 'frictionAndRisks'
            let options = this.teams.linkable_objectives;
            let index = _.findIndex(options, {id: Number(this.params.objective_id)});
            if(index != -1){
                this.objectives_tree_payload['name'] = options[index].name
            } else {
                this.objectives_tree_payload['name'] = 'No objective selected'
            }
            this.objectives_tree_payload['parent_objective_id'] = Number(this.params.objective_id)
            this.onOverItem('objectivesDropdown', false)
            //this.$bvModal.hide('linkedObjectiveModal');
        },
        showObjectiveModal(payload){
            this.modal_payload = _.cloneDeep(payload);
            this.modal_payload['modal_type'] = 'frictionAndRisks'
            let options = this.teams.linkable_objectives;
            let index = _.findIndex(options, {id: Number(payload.milestone_objective_id)});
            if(index != -1){
                this.modal_payload['name'] = options[index].name
            } else {
                this.modal_payload['name'] = 'No objective selected'
            }
            this.modal_payload['parent_objective_id'] = Number(payload.milestone_objective_id)
            this.show_objective_modal = true;
            setTimeout(()=>{
                this.$bvModal.show('linkedObjectiveModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "linkedObjectiveModal") {
                        this.show_objective_modal = false;
                        this.modal_payload = null;
                    }
                });
            }, 0)
        },
        handleLevelUpdateEvent(new_id) {
            this.params.level_id = new_id;
            this.onOverItem('levelsDropdown', false)
            let level = this.levels.find((lvl) => lvl.id == this.params.level_id);
            this.levels_tree_payload = { id: this.params.level_id, parent_id: level.parent_id, item_id: this.params.id, level_id: this.params.level_id};
            //this.$bvModal.hide('hierarchyModal')
        },
        showHierarchyModal(payload){
            this.modal_payload = _.cloneDeep(payload);
            console.log(this.modal_payload)
            let level_id = 0
            if(payload.level_id == undefined) {
                level_id = this.selected_level.id
            } else {
                level_id = payload.level_id
            }
            let level = this.levels.find((lvl) => lvl.id == level_id);
            this.modal_payload = { id: payload.level_id, parent_id: level.parent_id, item_id: payload.id, level_id: payload.level_id};
            this.modal_payload['modal_type'] = 'Objective'
            this.show_level_linking_modal = true;
            setTimeout(()=>{
                this.$bvModal.show('hierarchyModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "hierarchyModal") {
                        this.show_level_linking_modal = false;
                        this.modal_payload = null;
                    }
                });
            }, 0)
        },
        // showHierarchyModal() {
        //     this.modal_payload = this.params;
        //     let level = this.levels.find( level => {
        //         return level.id == this.params.level_id
        //     });
        //     if(level.parent_id) {
        //         this.modal_payload.parent_id = level.parent_id
        //     } else {
        //         this.modal_payload.parent_id = null
        //     }
        //     this.show_hierarchy_modal = true;
        //     setTimeout(()=>{
        //         this.$bvModal.show('levelLinkingModal');
        //         this.$root.$once("bv::modal::hidden", (event) => {
        //             if (event.type == "hidden" && event.componentId == "levelLinkingModal") {
        //                 this.show_hierarchy_modal = false;
        //             }
        //         });
        //     }, 1)
        // },
        save(){
            this.$emit('saveTable', [this.params]);
            this.$nextTick(() => {
                this.$bvModal.hide('frictionModal');
            })
        }
    },
    beforeDestroy() {
        this.modal_payload = {}
    },
    mounted(){
        if(this.payload.id) {
            this.params.id = this.payload.id
            this.params.name = this.payload.name
            this.params.objective_id = this.payload.objective_id
            this.params.friction_type = this.payload.friction_type
            this.params.level_id = this.payload.level_id;
        } else {
            this.params.level_id = this.selected_level.id;
        }

        this.objectives_tree_payload = _.cloneDeep(this.objectives_tree_payload);
        this.objectives_tree_payload['modal_type'] = 'frictionAndRisks'
        let options = this.teams.linkable_objectives;
        let index = _.findIndex(options, {id: Number(this.params.objective_id)});
        if(index != -1){
            this.objectives_tree_payload['name'] = options[index].name
        } else {
            this.objectives_tree_payload['name'] = 'No objective selected'
        }
        this.objectives_tree_payload['parent_objective_id'] = Number(this.params.objective_id)

        let level = this.levels.find((lvl) => lvl.id == this.params.level_id);
        this.levels_tree_payload = { id: this.params.level_id, parent_id: level.parent_id, item_id: this.params.id, level_id: this.params.level_id};
    },
}
</script>

<style>
/* #dropdownTree2 .dropdown-menu {
    max-width: 300%;
} */

#dropdownTree .dropdown-toggle {
    text-align: left;
}
#dropdownTree2 .dropdown-toggle {
    text-align: left;
}
</style>