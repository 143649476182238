<template>
    <b-row>
        <b-col cols="12">
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :id="table_uid"
                    :ref="table_uid"
                    :data="data"
                    :is_loading="is_loading"
                    :context_menu_items="['View', 'Edit', 'Delete']"
                    only_editable_columns
                    column_autoheight_key="name"
                    :active_id="-1"
                    :button_methods="button_methods"
                    hide_action_buttons
                    no_extra_rows
                    prevent_first_row_select
                    @selectedRowEvent="-1"
                    @saveTable="saveTable"
                    @deleteRows="deleteRow"         
                >
                    <template #title>
                        Responsibilities
                    </template>
                    <template #buttons>  
                        <b-button size="sm" variant="primary" @click="addResponsibilityModal({})" class="me-1" style="display: inline-block">
                            <font-awesome-icon :icon="['fas', 'fa-plus']" />
                        </b-button>
                    </template>
                </Table>
        </b-col>
        <ResponsiblityModal v-if="show_responsibility_modal" :payload="modal_payload" @saveTable="saveTable" />
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { format, parseISO } from "date-fns";
import _ from 'lodash'
import { levelComputed ,peopleComputed, profileComputed, profileMethods, peopleMethods } from '@/state/helpers'
import dateMixin from '@/mixins/dateMixin.js'
import Swal from 'sweetalert2'
import ResponsiblityModal from './jobTableModals.vue/responsiblityModal.vue';
export default {
    mixins:[ dateMixin ],
    name: 'responsibilityTable',
    components: {
        Table,
        ResponsiblityModal
    },
    props:{
        initial_table_mode:{
            default: 'view',
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'responsibilityTable',
        table_view_mode: 'view',
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        disableEdit: false,
        show_responsibility_modal: false,
        modal_payload: false
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        //* table computed methods
        columns(){
            // let el = this;
            let columns = [
                { id: 'name', header: 'Name', editor: 'text', fillspace: 1,
                },
                { id: 'description', header: 'Description', editor: 'text', fillspace: 1,
                },
                { id: "created_at", header: "Created at", editor: "date", sort: "date", width: 150, css: 'centerValues',
                    format:function(value){
                        //TODO   node/parentNode/childNodes/innerText
                        let date = _.cloneDeep(value);
                        if(date !== ''){
                        if(typeof date === 'string'){
                            date = parseISO(date);
                        }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                { id: "updated_at", header: "Updated at", editor: "date", sort: "date", width: 150, css: 'centerValues',
                    format:function(value){
                        //TODO   node/parentNode/childNodes/innerText
                        let date = _.cloneDeep(value);
                        if(date !== ''){
                        if(typeof date === 'string'){
                            date = parseISO(date);
                        }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    },
                },
                {
                    id: "actions", header: 'Action', width: 70, tooltip: false,  css: 'centerValues', editor: "text",
                    template(obj) {
                        if (obj.is_empty || obj.is_new) {
                            return '';
                        }
                        else {
                            return `<span class="action_text edit_action me-2"><i class="me-1 fas fa-edit"></i></span>`
                        }
                    }
                }
            ];
            return columns;
        },
        data(){
            if(!this.levelJob){
                return [];
            }
            else{
                return this.levelJob.responsibilities;
            }
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'edit_action':function(e, id){
                    let item = this.getItem(id)
                    el.addResponsibilityModal(item)
                },
            }
            return onClick;
        },
    },
    methods:{
        ...profileMethods,
        ...peopleMethods,
        //* table event handlers
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            if(row !== null){
                this.active_id = row.id;
            } else {
                this.active_id = -1
            }
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        saveTable(data){
            let array = [data]
            this.is_loading = true;
            let rows_to_save = [];
            array.forEach( row => {
                if(!row.is_empty){
                    let newRow = row.is_new;
                    var params = {
                        id: newRow ? -1 : row.id,
                        name: row.name,
                        description: row.description,
                    };
                    rows_to_save.push(params);
                }
            })

            let params = {
                job_id: this.levelJob.id,
                responsibilities: rows_to_save,
            }
            this.is_loading = true;
            this.saveJobResponsibilities(params).then(()=>{
                this.getLevelJob(this.levelJob.id)
                .then(()=>{
                    this.is_loading = false;
                    //this.$refs[this.table_uid].table_mode = 'view'
                })
            })
        },
        deleteRow(data) {
            let promises = [];
            this.is_loading = true;
            data.forEach( item => {
                promises.push(this.deleteJobResponsibilities({responsibility_id: item.id}));
            })
            Promise.all(promises)
            .then(()=>{
                this.getLevelJob(this.levelJob.id)
                .then(()=>{
                    this.is_loading = false;
                    this.$refs[this.table_uid].delete_array = []
                    //this.$refs[this.table_uid].table_mode = 'view'
                })
			})
            .catch(()=>{
                this.is_loading = false;
            })
        },
        addResponsibilityModal(item){
            this.modal_payload = _.cloneDeep(item);
            this.show_responsibility_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('addResponsibilityModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "addResponsibilityModal") {
                        this.show_responsibility_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
    },
    beforeMount(){
        this.table_view_mode = this.initial_table_mode;
    },
    mounted(){
    },
}
</script>

<style>

</style>