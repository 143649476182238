<template>
    <b-row>
        <b-col cols="12">
        <Table
            :uid="table_uid"
            :ref="table_uid" 
            :is_loading="is_loading"
            :data="job_skills"
            :columns="columns"
            :table_mode="table_view_mode"
            :context_menu_items="['View', 'Edit', 'Delete']"
            :pager_config=5
            hide_action_buttons
            no_extra_rows
            prevent_first_row_select
            :button_methods="button_methods"
            :validation="table_validation_rules"
            @updateViewMode="updateViewMode"
            @selectedRowEvent="selectedRowEvent"
            @saveTable="saveTable"
            @onDeleteArrayUpdate="onDeleteArrayUpdate"
            @deleteRows="deleteRow"      
            >
            <template #title>
                Skills
                &nbsp;
                >
                &nbsp;
                <span class="text-primary">{{selectedJobName}}</span>
            </template>
            <template #buttons>  
                <b-button size="sm" variant="primary" @click="addSkillsModal({})" class="me-1" style="display: inline-block">
                    <font-awesome-icon :icon="['fas', 'fa-plus']" />
                </b-button>
            </template>
            <!-- <template #buttons>
                    <b-button title="Add Skill Categories" class="m-0 me-1" size="sm" variant="primary" @click="showSkillCategoriesModal()">
                           Add Skill Categories
                    </b-button>
            </template> -->
        </Table>
        <skillsModal v-if="show_skills_modal" :payload="modal_payload" @saveTable="saveTable" />   
        </b-col>
    </b-row>
</template>

<script>
//import Table from '@/components/widgets/table.vue'
import Table from '@/components/widgets/table/table.vue'
import skillsModal from './jobTableModals.vue/skillsModal.vue';

//* misc imports
import Swal from 'sweetalert2';
// eslint-disable-next-line no-unused-vars
import _ from 'lodash';

import {
    levelComputed,
    peopleMethods,
    peopleComputed,
    profileComputed,
    profileMethods
    } from '@/state/helpers'
export default {
    components:{
        Table,
        skillsModal
    },
    data:() => ({
        //* Variables for the Table Component
        table_uid: 'jobSkillsTable',
        table_view_mode: 'view', //* (view - edit - delete)
        delete_array: [], //* array of items flagged to be deleted from table component
        changes_made_flag: false, //* true if the table component detects a change in a cell value
        is_loading: false,
        modal_payload: false,
        show_skills_modal: false
        //* ----
    }),
    watch: {
        levelJob() {
            if(this.levelJob != null) {
                this.is_loading = true
                window.webix.$$('jobSkillsTable').refresh()
                this.$nextTick(() => {
                    this.is_loading = false
                })
            }
        }
    },
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'edit_action':function(e, id){
                    let item = this.getItem(id)
                    el.addSkillsModal(item)
                },
            }
            return onClick;
        },
        competency_required() {
            let values = ['1','2','3','4','5','6','7','8','9','10']
            return values
        },
        category_options(){
            let names = [];
            
            if(this.skillCategories != undefined){
                this.skillCategories.forEach( item =>{
                    names.push({id: item.id, value: item.name});
                })
            }   
            return names;
        },
        job_skills(){
            if(this.levelJob) {
                return this.levelJob.skills
            } else {
                return []
            }
        },

        selectedJobName(){
            //* get job name by using the activeJobId from vuex store ( peopleComputed -> activeJobId ) ;
            let job_title = '';
            let index = _.findIndex(this.companyJobs, { id: this.activeJobId});
            if(index != -1){
                job_title = this.companyJobs[index].title;
            }
            return job_title;
        },
        columns(){
            let el=this
            return [
                { id: 'skill_name', header: 'Skill Name', fillspace: 1, editor: "text",},
                { id: 'category_id', header: 'Category', fillspace: 1,
                        template:function(obj){
                        console.log(obj);
                        if (obj.is_empty || obj.is_new) {
                            return '';
                        }
                        if(obj.category_id != undefined){
                            let index = _.findIndex(el.skillCategories, {id: obj.category_id });
                            let name = '';
                            if(index != -1){
                                name = el.skillCategories[index].name;
                            }
                            return name
                        }
                    },
                },
                { id: 'competency_required', header: 'Competency Required', fillspace: 1, css: 'centerValues'},
                { id: 'skill_description', header: 'Description', fillspace: 1, editor: "text",},
                {
                    id: "actions", header: 'Action', width: 70, tooltip: false,  css: 'centerValues', editor: "text",
                    template(obj) {
                        if (obj.is_empty || obj.is_new) {
                            return '';
                        }
                        else {
                            return `<span class="action_text edit_action me-2"><i class="me-1 fas fa-edit"></i></span>`
                        }
                    }
                }
            ]
        },
        table_validation_rules(){
            return {
                "skill_name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "category_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "competency_required": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "skill_description": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            }
        }
    },
    methods:{
        ...peopleMethods,
        ...profileMethods,
        addSkillsModal(item){
            this.modal_payload = _.cloneDeep(item);
            this.show_skills_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('addSkillsModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "addSkillsModal") {
                        this.show_skills_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        updateViewMode(mode){
            this.table_view_mode = mode;
        },
        getCategoryOptions(){
            let names = [];
            
            if(this.skillCategories != undefined){
                this.skillCategories.forEach( item =>{
                    names.push({category_id: item.id, value: item.name});
                })
            }   
            return names;
        },
        showDiscardPrompt(type){
            //* ask a user if they would like to cancel unsaved changes
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        //* table events
        selectedRowEvent(row){
            console.log('Use me -> ', row)
            //* Event returns the selected row
            //* here we save the seelcted row object or id in the people vuex store
            /*let index = _.findIndex(this.formatedObjectiveData, { id: row.id });
            if(index != -1){
                let objective = _.cloneDeep(this.formatedObjectiveData[index]);
                this.$eventHub.$emit('selectedObjective', objective);
                this.setSelectedObjective(objective);
            }*/
        },
        toggleViewMode(){
            if(this.changes_made_flag){
                this.showDiscardPrompt('view')
            }
            else{
                this.table_view_mode = 'view';
            }
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
        saveTable(data){
            let array = [data]
            let params = {
                job_id: this.levelJob.id,
                skills: []
            }
            this.$nextTick(()=>{
                array.forEach( row => {
                    if(!row.is_empty){
                        params.skills.push({
                            id: row.is_new ? -1 : row.id,
                            category_id: Number(row.category_id),
                            competency_required: Number(row.competency_required),
                            skill_name: row.skill_name,
                            skill_description: row.skill_description,
                        })
                    }
                });
                this.saveJobSkill(params)
                .then(()=>{
                    this.getLevelJob(this.levelJob.id)
                    .then(()=>{
                        this.is_loading = false;
                        this.loadCompanyJobs(this.company_id)
                        //this.$refs[this.table_uid].table_mode = 'view'
                    })
                })
            })
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected Skills?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.deleteRow()
                }
            });
        },
        deleteRow(data){
            console.log(JSON.parse(JSON.stringify(data)))
            let table = window.webix.$$(this.table_uid);
            let current_length = 0
            let rows = table.serialize(true); //* pass true to parse all data;
                rows.forEach( row => {
                    if(!row.is_empty){
                       current_length = current_length +1
                    }
                });
            
            let delete_length = data.length
            let promises = [];
            console.log(current_length);
            console.log(delete_length)
            if((current_length - delete_length) < 3) {
                Swal.fire({
                    title: "Please ensure the job has 3 skills",
                    //text: "3 Skills are required",
                    icon: "warning",
                    confirmButtonColor: "#34c38f",
                    confirmButtonText: "Ok",
                }).then((result) => {
                    if (result.value) {
                        //* If user selects yes
                        //this.table_view_mode = type;
                    }
                });
            } else {
                data.forEach( item => {
                promises.push(this.deleteJobSkill({'skill_id': item.id}));
                })
                this.is_loading = false;
                Promise.all(promises)
                .then(()=>{
                    this.delete_array = [];
                    //console.log('Action Plan table ref',this.$refs[this.table_uid])
                    this.loadCompanyJobs(this.company_id)
                    this.$refs[this.table_uid].table_mode = 'view';
                    this.is_loading = false;
                })
            }
        },
    },
    beforeMount(){
    },
    mounted(){
    },
}
</script>

<style>
.centerValues {
    text-align: center;
    vertical-align: middle;
}
</style>