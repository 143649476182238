<template>
    <b-modal centered title='Manage Used Service Provider' id='selectServiceProvidersModal' hide-footer>
        <b-overlay :show="is_saving">
            <!-- <vue-typeahead-bootstrap
                ref="serviceSearch"
                :data="data"
                v-model="query"
                placeholder="Search Service Providers"
                showOnFocus
                @hit="selectedItemEvent"
                prepend="Service Providers:"
            ></vue-typeahead-bootstrap> -->
            <webix-ui v-if="has_mounted" style="height: 100%;" v-model="tree_data" :config="ux" ></webix-ui>
            <!-- footer -->
            <div class='d-flex'>
                <b-button @click='save' class='ms-auto mt-2' variant='success'>Save</b-button>
            </div>
        </b-overlay>
    </b-modal>

</template>

<script>
//import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
//eslint-disable-next-line
import { cloneDeep, findIndex } from 'lodash';
import { levelComputed, profileComputed, profileMethods } from '@/state/helpers';
export default {
    components:{
        //eslint-disable-next-line
        //VueTypeaheadBootstrap,
    },
    props:{
    },
    data:() => ({
        //typeahead
        data:[],
        query: '',

        //webix
        tree_data:[],
        has_mounted: false,
        is_saving: false,
    }),
    computed:{
        ...levelComputed,
        ...profileComputed,
        ux(){
            let el = this;
            return {
                id: 'serviceProvidersTree',
                view: 'tree',
                css: 'levelTree',
                drag: false,
                tooltip: true,
                data: [] ,
                template: function(obj, common){
                    if(obj.show_checkbox){
                        return `<span class="checkbox-padding-fix ms-1">${common.checkbox(obj, common)} ${obj.name}</span>`
                    }
                    else{
                        return common.icon(obj, common) + common.folder(obj, common) + ` ${obj.name}`
                    }
                } ,
                // on:{
                //     //eslint-disable-next-line
                //     onBeforeContextMenu:function(id, e, node){
                //         el.right_clicked_id = id;
                //         let ctx_menu =  window.webix.$$('assignmentContextMenu');
                //         if(ctx_menu != undefined){
                //             ctx_menu.clearAll();
                //             let options = el.contextMenuItems;
                //             ctx_menu.define('data', options);
                //             ctx_menu.refresh();
                //         }
                //     },
                // },
                ready(){
                        let array = cloneDeep(el.teams.client_service_providers)
                        array.forEach(( item )=>{
                        item['show_checkbox'] = true;
                        if(item){
                            let branch_id = el.selected_level.id
                            //TODO - remove the level_id part
                            item.id = `${branch_id}|item-${item.id}`
                            //*insert user leaf into the linked level branch
                            //*{ user_object, level_id (parent)}
                            this.add(item, 0,branch_id);
                            console.log(item, 0, branch_id)
                        }                     
                    })
                    //*show users under folders
                    this.sort('#show_checkbox#');
                    this.open(this.getFirstId());
                    el.setInitialChecked();
                },
                scroll: true,
            }
        },
    },
    methods:{
        ...profileMethods,
        save(){
            let tree = window.webix.$$('serviceProvidersTree');
            let checked_items = tree.getChecked();
            this.$emit('saveTable', checked_items)
            this.$bvModal.hide('workMembersModal');
        },
        populateList(){
            // let users = [];
            // this.all_users.forEach( user =>{
            //     users.push(`${user.name} | ${user.email}`)
            // });
            // this.users = users;
        },
        processTree(data){
            data.forEach((element) => {
                // give element the required fields
                element["parent"] = element.parent_id; //used for webix related stuff
                element["value"] = element.name; //used for webix related stuff
                element['data'] = []; // array of children for the node
            });
            data.forEach((ele) => {
                let parent_id = ele.parent_id;
                if (parent_id != null) {
                //* If element is a child element, push to children array.
                    //let users_array = [];
                    data.forEach((d) => {
                        if (d.id === parent_id) {
                            ele['flagged'] = true;
                            let childArray = d.data;
                            if (!childArray) {
                                childArray = [];
                            }
                            childArray.push(ele);
                            d.data = childArray;
                        }
                        else{
                            //TODO - verify this fixed duplicates not being removed
                            let index = findIndex(data, {id: d.parent_id});
                            if(index == -1){
                                d.parent_id = null ;
                            }
                        }
                    });
                }
            });
            //* Remove the leftover / duplicate elements
            //data = data.filter((ele) => ele.parent_id != ele.id);
            //data = data.filter((ele) => ele.parent_id == null);
            //* Remove duplicate elements
            data = data.filter((ele) => ele.data != undefined || ele.parent_id == null);
            //* fix for activity-other objectives not showing children as a tree
            data = data.filter( (ele) => ele.flagged == undefined );
            this.$nextTick(()=>{
                this.has_mounted = true;
            })
            return data;
        },
        setInitialChecked(){
            let tree = window.webix.$$('serviceProvidersTree');
            if(tree){
                   this.teams.client_service_providers.forEach(provider => {
                    //  if(team.id == this.selected_team_id) {
                    //     team.team_members.forEach( users => {
                    //         let user = this.all_users.find( item => {
                    //             return item.id == users.user_id
                    //         });
                    //         console.log(user)
                    //         if(user){
                    //             user.user_levels.forEach( user_level => {
                    //                 let user_in_tree_id = `${user_level.id}|user-${user.id}`;
                    //                 if(tree.exists(user_in_tree_id)){
                    //                     tree.checkItem(user_in_tree_id);
                    //                 }
                    //             })
                    //         }
                    //     })
                    //  }
                    this.teams.service_providers.forEach(item => {
                        if(item.client_service_provider_id == provider.id) {
                            let in_tree_id = `${this.selected_level.id}|user-${item.client_service_provider_id}`;
                            console.log(in_tree_id)
                            if(tree.exists(in_tree_id)){
                                tree.checkItem(in_tree_id);
                            }
                        }
                    })    
                })
                this.openTreeNode(tree, tree.getChecked());
                }
        },
        openTreeNode(tree_component, id_array){
            id_array.forEach( id => {
                let item = tree_component.getItem(id);
                while(item.$parent != 0){
                    tree_component.open(item.$parent);
                    item = tree_component.getItem(item.$parent);
                }
            })
        },
        //* typeahead
        // selectedItemEvent(item){
        //     //* assumes there is a '|' character as a delimeter (included a blank space as well)
        //     let email = item.split('| ')[1];
        //     let user = this.teams.client_service_providers.find( item => {
        //         return item.email == email;
        //     });
            
        //     if(user){
        //         // if(user.id == this.payload.owner_user_id){
        //         //     this.$swal.fire(`${user.name} (${email}) is the owner of the current project and can't be added as a reponsible user`);
        //         //     return;
        //         // }
        //         this.teams.teams.team_members.forEach(member => {
        //             if(member.id == user.id && member.lead_user) {
        //                 this.$swal.fire(`${user.name} (${email}) is the owner of the current project and can't be added as a reponsible user`);
        //                 return
        //             }
        //         })
        //         let tree = window.webix.$$('serviceProvidersTree');
        //         let matches = [];
        //         user.user_levels.forEach( user_level => {
        //             matches.push(`${user_level.id}|user-${user.id}`)
        //         });

        //         matches.forEach( item => {
        //             if(tree.isChecked(item)){
        //                 tree.uncheckItem(item);
        //             }
        //             else{
        //                 tree.checkItem(item);
        //             }
        //         })
        //         this.openTreeNode(tree, matches);
        //         this.resetTypeahead();
        //     }
        // },
        // resetTypeahead(){
        //     this.$nextTick(()=>{
        //         this.query = '';
        //         let input = this.$refs['serviceSearch'].$refs['input'];
        //         input.blur();
        //     })
        // },
},
    mounted(){
        let levels_with_users = cloneDeep(this.levels);
        levels_with_users.forEach( element => element['data'] = []);
        this.tree_data = this.processTree(levels_with_users);
        this.populateList();
    },
}
</script>

<style>
.webix_view.webix_window.webix_popup.webix_menu{
    z-index: 1041 !important;
}

.checkbox-padding-fix .webix_tree_checkbox{
    margin-top: 2px;
}
</style>