<template>
    <b-row>
        <b-col cols="12">
            <b-card>
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :ref="table_uid"
                    :data="processes_data"
                    :is_loading="is_loading"
                    :hide_action_buttons="true"
                    :button_methods="button_methods"
                    disable_delete
                    guideline_key="process_procedures_table"
                >
                    <template #title>
                        Processes
                    </template>
                </Table>
            </b-card>
        </b-col>
        <b-col cols="12">
            
        </b-col>
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { levelComputed, peopleComputed, profileComputed } from '@/state/helpers'
import { format, parseISO } from 'date-fns';
export default {
    name: 'processes',
    components: {
        Table,
    },
    props:{
        initial_table_mode:{
            default: 'view',
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'processes',
        table_view_mode: 'view',
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        columns(){
            //* get ref of current scope
            let process_columns = [
                { id: 'title', header: 'Document Title', fillspace: 3, editor: 'text', 
                    template: (value) => {
                        if(value != ''){
                            return `<span class="action_text view_action cursor-pointer">${value.title}</span>`
                        }
                        else {
                            return '';
                        }
                    }
                },//{ content:"richSelectFilter" }},
                { id: 'created_at', header: 'Issue Date', fillspace: 1,
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
                { id: 'owner_name', header: 'Owner', fillspace: 1, editor: 'text' },
                {
                    id: "type", header: "Type", tooltip: false,
                    template: (obj) => {
                        if(obj.type) {
                            return obj.type.charAt(0).toUpperCase() + obj.type.slice(1)
                        } else {
                            return '' 
                        }
                    }
                },
                { id: 'level_name', header: 'Org Structure', fillspace: 2, editor: 'text' },
                { id: 'status', header: 'Status', fillspace: 1, editor: 'text' },
            ];
            return process_columns;
        },
        processes_data(){
            if(this.teams.processes == undefined && this.teams.procedures == undefined){
                return [];
            }
            else{
                let data1 = this.teams.processes
                let data2 = this.teams.procedures
                let final = data1.concat(data2);
                return  final;
            }
        },
        button_methods(){
            let el = this;
            let onClick = {
                //eslint-disable-next-line
                'view_action':function(e, id){
                    let item = this.getItem(id.row);
                    console.log(item);
                       el.$router.push(`/handbook/${item.id}`);
                },
            }
            return onClick;
        },
    },
    methods:{
        //* table event handlers
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            if(row !== null){
                this.active_id = row.id;
            } else {
                this.active_id = -1
            }
        },
    },
    mounted(){},
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
</style>