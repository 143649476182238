<template>
    <b-row>
        <b-col cols="12">
                <div class="d-flex">
                    <b-alert :show="service_providers_data_check" class="w-100 me-1 ms-1 alert" style="display:flex; justify-content: space-between; align-items: center; height: 44px" variant="primary"><b>No Service Providers Found</b>
                        <b-button size="sm" style="float: right;" variant="success" @click="showServiceProvidersModal">
                            <font-awesome-icon :icon="['fas', 'fa-wrench']" /> Add Service Provider
                        </b-button>
                    </b-alert>
                </div>
                <Table
                    :columns="columns"
                    :uid="table_uid"
                    :ref="table_uid"
                    :simple_columns="simple_columns"
                    :data="service_data"
                    :is_loading="is_loading"
                    :pager_config=10
                    :context_menu_items="['View', 'Edit', 'Delete']"
                    only_editable_columns
                    :active_id="active_id"
                    @updateViewMode="handleTableModeUpdate"
                    :validation="table_validation_rules"
                    @selectedRowEvent="selectedRowEvent"
                    @saveTable="saveTable"
                    hide_action_buttons
                    @deleteRows="deleteRow"
                    :edit_permissions="{manager:'providers-tools'}"
                    :required_permissions="['providers-tools']"
                    guideline_key="service_providers_table"
                >
                    <template #title>
                        Service Providers
                    </template>
                    <template #buttons>
                        <b-button size="sm" variant="primary" @click="selectServiceProviders({})" class="me-1" style="display: inline-block">
                            <font-awesome-icon :icon="['fas', 'fa-plus']" />
                        </b-button>
                        <b-button size="sm" variant="primary" title="Manage Service Providers" @click="showServiceProvidersModal" class="me-1" style="display: inline-block">
                                <font-awesome-icon :icon="['fas', 'fa-wrench']" />
                        </b-button>
                    </template>
                </Table>
                <serviceProvidersModal
                    v-if="show_service_providers_modal"
                    @saved="setOptions"
                />
                <SelectServiceProviders v-if="show_select_service_providers_modal" @saveTable="saveTable" />
        </b-col>

    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { levelComputed ,peopleComputed, profileComputed, profileMethods } from '@/state/helpers'
import serviceProvidersModal from './profileModals/serviceProvidersModal.vue'
import Swal from 'sweetalert2'
import SelectServiceProviders from './profileModals/selectServiceProviders.vue';
export default {
    name: 'serviceProviders',
    components: {
        Table,
        serviceProvidersModal,
        SelectServiceProviders
    },
    props:{
        initial_table_mode:{
            default: 'view',
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'serviceProviders',
        table_view_mode: 'view',
        is_loading: false,
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],
        show_service_providers_modal: false,
        service_provider_options: [],
        service_provider_table_view_mode: 'view',
        show_select_service_providers_modal: false
    }),
    watch: {
        // client_service_providers: {
        //     handler() {
        //         if(this.teams.client_service_providers.length != 0) {
        //             this.setOptions()
        //         }
        //     }
        // }
    },
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        //* table computed methods
        columns(){
            let el = this;
            //* get ref of current scope
            let service_columns = [
                { id: 'client_service_provider_id', header: 'Name', fillspace: 1, editor: "richselect", options: el.availableServiceProviderNames },
                { id: "frequency", header: "Frequency", editor: "combo",options: [
                        { id: "daily", value: "Daily" },
                        { id: "weekly", value: "Weekly" },
                        { id: "monthly", value: "Monthly" },
                        { id: "quarterly", value: "Quarterly" },
                        { id: "yearly", value: "Yearly" },
                    ],  fillspace: 1},   
            ];
            return service_columns;
        },
        simple_columns(){
            //let el = this;
            //* get ref of current scope
            let service_columns = [
                { id: 'provider_no', header: 'Provider Number', fillspace: 1, },
                { id: 'name', header: 'Name', fillspace: 1, editor: "combo", },
                { id: 'type', header: 'Product/Service', fillspace: 2, },
                { id: "frequency", header: "Frequency"},
                { id: 'contact_person', header: 'Contact Person', fillspace: 2, },
                { id: 'contact_no', header: 'Number', fillspace: 2, },
                { id: 'email', header: 'Email', fillspace: 2, },
                
            ];
            return service_columns;
        },
        service_data(){
            if(this.teams.service_providers == undefined){
                return [];
            }
            else{
                console.log(this.teams.service_providers)
                return this.teams.service_providers;
            }
        },
        service_providers_data_check() {
            if(this.teams.client_service_providers == undefined){
                return true;
            }
            else{ 
                if(this.teams.client_service_providers.length == 0){
                    return true
                } else {
                    return false
                }
            }
            
        },
        availableServiceProviderNames(){
            if(this.teams.client_service_providers){
                return this.teams.client_service_providers.map( item => {
                    console.log(JSON.parse(JSON.stringify(item)));
                    return {
                        id: item.id,
                        value: item.name,
                    }
                })
            }
            else{
                return [];
            }
        },
        availableServiceProviderNo(){
            if(this.teams.client_service_providers){
                return this.teams.client_service_providers.map( item => {
                    return {
                        id: item.id,
                        value: item.provider_no,
                    }
                })
            }
            else{
                return [];
            }
        },
        availableServiceProviderProductService(){
            if(this.teams.client_service_providers){
                return this.teams.client_service_providers.map( item => {
                    return {
                        id: item.id,
                        value: item.type
                    }
                })
            }
            else{
                return [];
            }
        },
        availableServiceProviderContactPerson(){
            if(this.teams.client_service_providers){
                return this.teams.client_service_providers.map( item => {
                    return {
                        id: item.id,
                        value: item.contact_person
                    }
                })
            }
            else{
                return [];
            }
        },
        availableServiceContactNo(){
            if(this.teams.client_service_providers){
                return this.teams.client_service_providers.map( item => {
                    return {
                        id: item.id,
                        value: item.contact_no
                    }
                })
            }
            else{
                return [];
            }
        },
        availableServiceProviderEmail(){
            if(this.teams.client_service_providers){
                return this.teams.client_service_providers.map( item => {
                    return {
                        id: item.id,
                        value: item.email
                    }
                })
            }
            else{
                return [];
            }
        },
        table_validation_rules(){
            let el = this
            return {
                "client_service_provider_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                                let data = window.webix.$$(el.table_uid).serialize(null, true);
                                let value = false
                                data.forEach(row => {
                                    if(row.client_service_provider_id == obj.client_service_provider_id && row != obj) {
                                        console.log("HITTING HERE");
                                        value = true
                                    }
                                })
                                if(value) {
                                    let name = ''
                                    el.teams.client_service_providers.forEach(item => {
                                        if(Number(obj.client_service_provider_id) == item.id) {
                                            console.log("getting in here")
                                            name = "" + item.name + ""
                                        }
                                    })
                                    console.log(name)
                                    Swal.fire({
                                        title: 'Service Provider "' + name + '" is already in use on this level',
                                        icon: "warning",
                                    });
                                    return false
                                } else {
                                    return true
                                }
                             }
                            
                        //}
                    }
                },
                // "type": function(item, obj){
                //     if(obj.is_empty){
                //         return true;
                //     }
                //     else{
                //         if(item === '' || item == undefined){
                //             return false;
                //         }
                //         else{
                //             return true;
                //         }
                //     }
                // },
                "frequency": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
            };
        },
    },
    methods:{
        ...profileMethods,
        selectServiceProviders(){
            this.show_select_service_providers_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('selectServiceProvidersModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "selectServiceProvidersModal") {
                        this.show_select_service_providers_modal = false;
                    }
                });
            })
        },
        handleTableModeUpdate(new_mode){ 
            this.service_provider_table_view_mode = new_mode
        },
        showServiceProvidersModal(){
            //if(this.service_provider_table_view_mode == 'view') {
                this.show_service_providers_modal = true;
                this.$nextTick(()=>{
                    this.$bvModal.show('serviceProvidersModal');
                    this.$root.$once("bv::modal::hidden", (event) => {
                        if (event.type == "hidden" && event.componentId == "serviceProvidersModal") {
                            this.show_service_providers_modal = false;
                            window.webix.$$(this.table_uid).refresh();
                        }
                    });
                })
            //}
            
        },
        //* table event handlers
        selectedRowEvent(row){
            //* set selected row id  in your vuex store
            if(row !== null){
                this.active_id = row.id;
            } else {
                this.active_id = -1
            }
        },
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        showDeletePrompt(){
            Swal.fire({
                title: "Delete selected milestones?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    //* example code (the deleteMilestone() is a method from the activity store)
                }
            });
        },
        saveTable(data){
            console.log(JSON.parse(JSON.stringify(data)))
            this.is_loading = true;          
            let rows_to_save = [];
            data.forEach( row => {   
                //if(!row.is_empty){
                    let mainId = row.split('|')[1];
                    let plainId = mainId.split('-')[1]
                    let client_service_provider = null
                    this.teams.client_service_providers.forEach(item => {
                        if(item.id == plainId) {
                            client_service_provider = item
                        }
                    })
                    //let newRow = row.is_new;
                    var params = {
                        id: -1,
                        level_id: this.selected_level.id,
                        client_service_provider_id: client_service_provider.id,
                        name: client_service_provider.name,
                        type: client_service_provider.type,
                        frequency: null,
                        contact_person: client_service_provider.contact_person,
                        contact_no: client_service_provider.contact_no,
                        email: client_service_provider.email,
                        provider_no: client_service_provider.provider_no
                    };
                    rows_to_save.push(params);
                //}
            })
            console.log(rows_to_save)
            let params = {
                service_providers: rows_to_save,
            }
            this.is_loading = true;
            this.saveServiceProviders(params).then(()=>{
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.setOptions();
                        this.is_loading = false;
                        this.$refs[this.table_uid].table_mode = 'view'
                    })
                })
        },
        deleteRow(data) {
            let promises = [];
            this.is_loading = true;
            data.forEach( item => {
                promises.push(this.deleteServiceProviders({id: item.id}));
            })
            Promise.all(promises)
            .then(()=>{
                this.loadTeams(this.selected_level.id)
                .then(()=>{
                    this.setOptions()
                    this.$refs[this.table_uid].table_mode = 'view';
                    this.is_loading = false;
                })
			})
            .catch(()=>{
                this.is_loading = false;
            })
        },
        setOptions() {
            this.service_provider_options = [];
            let data_array = []
            let data = this.teams.client_service_providers;
            if(data) {
                data.forEach(item => {
                    data_array.push(item.name)
                })
                this.service_provider_options = data_array
            }
        }
    },
    mounted(){
        this.setOptions()
    },
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
</style>