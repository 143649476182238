<template>
    <b-modal id="assetModal" centered title="Add Asset" size="lg" hide-footer>
        <b-form @submit.prevent>
            <div class="container">
                <div class="row">
                    <div class="col-sm p-0">
                        <b-form-group class="label" id="input-group-1" label="Asset Name:" label-for="input-1" size="sm">
                            <b-form-input
                            id="input-1"
                            size="sm"
                            v-model="params.asset_name"
                            placeholder="Enter Asset Name"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                    <div class="col-sm p-0" style="margin-left: 4px;">
                        <b-form-group class="label" id="input-group-2" label="Serial Number:" label-for="input-2" size="sm">
                            <b-form-input
                            id="input-2"
                            size="sm"
                            v-model="params.serial_no"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
            </div>
           
            <div class="container">
                <div class="row">
                    <div class="col-sm p-0">
                        <b-form-group id="input-group-3" label="Organisation Structure:" label-for="input-3" size="sm" >
                        <b-row>
                            <span @mouseover="onOver" >
                                <b-dropdown id="dropdownTree" ref="dropdown" no-caret :text="getLevelForID" variant="ouline" size="sm" class="dropdownStyles dropdown-toggle-no-carret">
                                    <span class="dropdown-item dropdownItemStyles dropdown-toggle-no-carret" no-caret @click.stop="" href="#"><HierarchyTree class="dropdown-toggle-no-carret" v-if="show_tree" no-caret @selectedNewID="handleLevelUpdateEvent" :payload="tree_payload" show_all_levels /></span>
                                </b-dropdown>
                            </span>
                        </b-row>
                    </b-form-group>
                    </div>
                    <div class="col-sm p-0" style="margin-left: 4px;">
                        <b-form-group id="input-group-4" label="Purchased Date:" label-for="input-4" size="sm" >
                            <b-form-input
                            id="input-4"
                            size="sm"
                            type="date"
                            v-model="params.purchased_date"
                            placeholder="Expiry Date"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
            </div>
            

            <div class="text-end pt-2 mt-3">
                <b-button variant="light" @click="$bvModal.hide('assetModal')">Close</b-button>
                <b-button @click="save()" variant="success" class="ms-1">Save</b-button>
            </div>
        </b-form>
        <HierarchyModal @selectedNewID="handleLevelUpdateEvent" :payload="modal_payload" show_all_levels v-if="show_level_linking_modal" />
    </b-modal>
</template>

<script>
import { levelComputed, peopleMethods, peopleComputed } from "@/state/helpers";
import HierarchyModal from '@/components/objectives/components/misc/hierarchyModal.vue'
import HierarchyTree from '@/components/objectives/components/misc/dropdownComponents/hierarchyTree.vue'
import {parseISO, format} from 'date-fns'
import _ from 'lodash'
// /import _ from 'lodash'
export default {
    components:{
        HierarchyModal,
        HierarchyTree 
    },
    props:{
        payload:{
            require: false
        },
    },
    data:() => ({
        params:{
            id: 0,
            asset_name:'',
            level_id: null,
            serial_no: '',
            purchased_date: '',
            modal_type: 'objective',
        },
        modal_payload: {},
        tree_payload: {},
        show_level_linking_modal: false,
        show_tree: false
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        getLevelForID(){
            let level = this.levels.find( level => {
                return level.id == this.params.level_id
            });
            if(level) {
                return level.name
            } 
            return 'No Level Linked'
        },
    },
    methods:{
        ...peopleMethods,
        onOver() {
            this.show_tree = true
            this.$refs.dropdown.visible = true;
        },
        onLeave() {
            this.show_tree = false
            this.$refs.dropdown.visible = false;
        },
        handleLevelUpdateEvent(new_id) {
            this.params.level_id = new_id;
            this.onLeave()
            let level = this.levels.find((lvl) => lvl.id == this.params.level_id);
            this.tree_payload = { id: this.params.level_id, parent_id: level.parent_id, item_id: this.params.id, level_id: this.params.level_id};
            console.log(this.tree_payload)
            //this.$bvModal.hide('hierarchyModal')
        },
        showHierarchyModal(payload){
            this.modal_payload = _.cloneDeep(payload);
            console.log(this.modal_payload)
            let level_id = 0
            if(payload.level_id == undefined) {
                level_id = this.selected_level.id
            } else {
                level_id = payload.level_id
            }
            let level = this.levels.find((lvl) => lvl.id == level_id);
            this.modal_payload = { id: payload.level_id, parent_id: level.parent_id, item_id: payload.id, level_id: payload.level_id};
            this.modal_payload['modal_type'] = 'Objective'
            this.show_level_linking_modal = true;
            setTimeout(()=>{
                this.$bvModal.show('hierarchyModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "hierarchyModal") {
                        this.show_level_linking_modal = false;
                        this.modal_payload = null;
                    }
                });
            }, 0)
        },
        save(){
            this.$emit('saveTable', [this.params]);
            this.$nextTick(() => {
                this.$bvModal.hide('assetModal');
            })
        }
    },
    beforeDestroy() {
        this.modal_payload = {}
    },
    mounted(){
        if(this.payload.id) {
            let modified_date = new Date(this.payload.purchased_date)
            this.params.id = this.payload.id
            this.params.asset_name = this.payload.asset_name
            this.params.serial_no = this.payload.serial_no
            this.params.purchased_date = format(parseISO(modified_date.toISOString()), 'yyyy-MM-dd')
            this.params.level_id = this.payload.level_id;
        } else {
            this.params.level_id = this.selected_level.id;
        }
        let level = this.levels.find((lvl) => lvl.id == this.params.level_id);
        this.tree_payload = { id: this.params.level_id, parent_id: level.parent_id, item_id: this.params.id, level_id: this.params.level_id};
    },
}
</script>

<style>
.dropdownStyles {
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    font-size: 0.7rem;
    min-width: 100%;
}
.dropdownItemStyles {
    width: max-content !important;
}
.dropdownItemStyles:hover {
    background-color: unset !important;
}

#dropdownTree .dropdown-menu {
    width: 100%;
}

.dropdown-toggle-no-carret::after { content: none !important }

.dropdown-toggle::after{display:none !important}


#dropdownTree .dropdown-item {
    min-width: 100%;
}

#dropdownTree .dropdown-toggle {
    text-align: left;
}

.webix_view.webix_tree {
    width: 100% !important
}

</style>