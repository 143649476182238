<template>
    <b-modal id="addSkillsModal" size="lg" :title="params.id == -1 ? 'Add Skill' : 'Update Skill'" hide-footer>
        <b-form @submit.prevent>
            <b-row>
                <b-col cols="12">
                    <b-row class="mb-1">
                        <b-col cols="2">
                            <label class="col-form-label" for="text">Name:</label>
                        </b-col>
                        <b-col cols="10">
                            <b-form-input required v-model="params.skill_name"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="2">
                            <label class="col-form-label" for="text">Category:</label>
                        </b-col>
                        <b-col cols="4">
                            <b-form-select class="form-select" required v-model="params.category_id" :options="category_options"></b-form-select>
                        </b-col>
                        <b-col cols="2">
                            <label class="col-form-label" for="text">Competency</label>
                        </b-col>
                        <b-col cols="4">
                            <b-form-select class="form-select" required v-model="params.competency_required" :options="competency_required"></b-form-select>
                        </b-col>
                    </b-row>
                    <b-row>
                        
                    </b-row>
                    <b-row class="mb-1">
                        <b-col cols="2">
                            <label class="col-form-label" for="text">Description:</label>
                        </b-col>
                        <b-col cols="10">
                            <b-form-textarea style="height: 100px" required v-model="params.skill_description"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <div class="text-end pt-2 mt-3">
                <b-button variant="light" @click="$bvModal.hide('addSkillsModal')">Close</b-button>
                <b-button @click="save()" variant="success" class="ms-1">Save</b-button>
            </div>
        </b-form>
    </b-modal>
</template>

<script>
import { levelComputed, peopleMethods, peopleComputed } from "@/state/helpers";
export default {
    props:{
        payload:{
            require: false
        },
    },
    data:() => ({
        namesList: [],
        params:{
            id: -1,
            skill_name:'',
            category_id: 1,
            competency_required: 1,
            skill_description:''
        },
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        competency_required() {
            let values = ['1','2','3','4','5','6','7','8','9','10']
            return values
        },
        category_options(){
            let names = [];
            
            if(this.skillCategories != undefined){
                this.skillCategories.forEach( item =>{
                    names.push({value: item.id, text: item.name});
                })
            }   
            return names;
        },
    },
    methods:{
        ...peopleMethods,
        loadCategoryOptions(){
            let names = []
            console.log(this.skillCategories)
            if(this.skillCategories){
                this.skillCategories.forEach( item =>{
                    names.push({value: item.id, text: item.name});
                })
            }
            console.log(names)
            this.namesList = names
        },
        save(){
            this.$emit('saveTable', this.params);
            this.$nextTick(() => {
                this.$bvModal.hide('addSkillsModal');
            })
        }
    },
    beforeDestroy() {
        this.modal_payload = {}
    },
    mounted(){
        console.log(JSON.parse(JSON.stringify(this.payload)))
        if(this.payload.id) {
            this.params.id = this.payload.id
            this.params.skill_name = this.payload.skill_name
            this.params.category_id = this.payload.category_id
            this.params.competency_required = this.payload.competency_required
            this.params.skill_description = this.payload.skill_description
        }
        this.loadCategoryOptions()
        // if(this.type == 'child'){
        //     this.params.parent_id = this.parent_id;
        // }
        // this.params.level_id = this.selected_level.id;
    },
}
</script>

<style>

</style>